import { motion } from "framer-motion";
import { Button } from "@components/ui/button";

export const SupportSeccion = () => {
  return (
    <div className="mt-24">
      <h2 className="text-center text-3xl font-extrabold text-gray-900">
        Soporte
      </h2>
      <div className="mt-12 grid gap-8 grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
        {[
          {
            title: "Centro de ayuda",
            description:
              "Encuentra respuestas a tus preguntas en nuestra extensa base de conocimientos.",
            buttonText: "Visitar centro de ayuda",
          },
          {
            title: "Soporte por email",
            description:
              "Contáctanos directamente y recibirás una respuesta en menos de 24 horas.",
            buttonText: "Enviar email",
          },
          {
            title: "Chat en vivo",
            description:
              "Obtén ayuda instantánea de nuestro equipo de soporte en tiempo real.",
            buttonText: "Iniciar chat",
          },
        ].map((item, index) => (
          <motion.div
            key={index}
            className="border rounded-lg shadow-lg p-6"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: index * 0.1 }}
            whileHover={{ scale: 1.05 }}
          >
            <h3 className="text-xl font-semibold text-gray-900">
              {item.title}
            </h3>
            <p className="mt-4 text-gray-500">{item.description}</p>
            <Button variant="outline" className="mt-6">
              {item.buttonText}
            </Button>
          </motion.div>
        ))}
      </div>
    </div>
  );
};
