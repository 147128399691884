import { useState } from "react";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@components/ui/select";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@components/ui/card";
import {
  Filter,
  FileText,
  CheckSquare,
  MessageSquare,
  Clock,
  User,
} from "lucide-react";
import Navbar from "@components/narbar/narbar";

interface Activity {
  id: number;
  type: string;
  description: string;
  timestamp: string;
  user: string;
}

export default function Activities() {
  const [activities /* setActivities */] = useState<Activity[]>([
    {
      id: 1,
      type: "task",
      description: 'Se creó la tarea "Desarrollar API REST"',
      timestamp: "2024-08-31T14:30:00Z",
      user: "Ana García",
    },
    {
      id: 2,
      type: "file",
      description: 'Se subió el archivo "diseño_inicial.pdf"',
      timestamp: "2024-08-31T15:45:00Z",
      user: "Carlos Rodríguez",
    },
    {
      id: 3,
      type: "comment",
      description: 'Nuevo comentario en la tarea "Implementar autenticación"',
      timestamp: "2024-08-31T16:20:00Z",
      user: "Laura Martínez",
    },
    {
      id: 4,
      type: "task",
      description: 'Se completó la tarea "Configurar entorno de desarrollo"',
      timestamp: "2024-08-31T17:10:00Z",
      user: "David Sánchez",
    },
  ]);
  const [filter, setFilter] = useState("all");

  const getIcon = (type: string) => {
    switch (type) {
      case "task":
        return <CheckSquare className="h-5 w-5 text-blue-500" />;
      case "file":
        return <FileText className="h-5 w-5 text-green-500" />;
      case "comment":
        return <MessageSquare className="h-5 w-5 text-yellow-500" />;
      default:
        return <Clock className="h-5 w-5 text-gray-500" />;
    }
  };

  const filteredActivities = activities.filter((activity) => {
    if (filter === "all") return true;
    return activity.type === filter;
  });

  return (
    <div className="min-h-screen bg-gray-100">
      <Navbar />
      <main className="container mx-auto px-4 py-8">
        <Card className="w-full max-w-3xl mx-auto mt-8 shadow-lg">
          <CardHeader className="bg-white rounded-t-lg">
            <CardTitle className="text-2xl font-bold text-gray-800">
              Actividades Recientes
            </CardTitle>
            <CardDescription className="text-gray-600">
              Historial de acciones en ZapTrack
            </CardDescription>
          </CardHeader>
          <CardContent className="bg-white rounded-b-lg">
            <div className="flex justify-between items-center mb-6">
              <Select onValueChange={setFilter} defaultValue={filter}>
                <SelectTrigger className="w-[180px] bg-white border border-gray-300">
                  <SelectValue placeholder="Filtrar actividades" />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="all">Todas</SelectItem>
                  <SelectItem value="task">Tareas</SelectItem>
                  <SelectItem value="file">Archivos</SelectItem>
                  <SelectItem value="comment">Comentarios</SelectItem>
                </SelectContent>
              </Select>
              <Filter className="h-5 w-5 text-gray-500" />
            </div>
            <ul className="space-y-4">
              {filteredActivities.map((activity) => (
                <li
                  key={activity.id}
                  className="flex items-start space-x-3 bg-gray-50 p-4 rounded-lg shadow-sm transition duration-150 ease-in-out hover:bg-gray-100"
                >
                  {getIcon(activity.type)}
                  <div className="flex-1">
                    <p className="text-sm font-medium text-gray-900">
                      {activity.description}
                    </p>
                    <div className="flex items-center mt-1 space-x-2">
                      <User className="h-4 w-4 text-gray-400" />
                      <p className="text-xs text-gray-600">{activity.user}</p>
                    </div>
                    <p className="text-xs text-gray-500 mt-1">
                      {new Date(activity.timestamp).toLocaleString()}
                    </p>
                  </div>
                </li>
              ))}
            </ul>
          </CardContent>
        </Card>
      </main>
    </div>
  );
}
