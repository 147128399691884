import { Button } from "@components/ui/button";
import { motion } from "framer-motion";
import { Check } from "lucide-react";

const pricingPlans = [
  {
    name: "Básico",
    price: "$9.99",
    features: [
      "5GB de almacenamiento",
      "Hasta 10 usuarios",
      "Soporte por email",
    ],
  },
  {
    name: "Pro",
    price: "$29.99",
    features: [
      "50GB de almacenamiento",
      "Usuarios ilimitados",
      "Soporte prioritario",
      "Módulo contable",
    ],
  },
  {
    name: "Empresa",
    price: "$99.99",
    features: [
      "500GB de almacenamiento",
      "Usuarios ilimitados",
      "Soporte 24/7",
      "Todas las características",
    ],
  },
];

export const PricingSeccion = () => {
  return (
    <div className="mt-24">
      <h2 className="text-center text-3xl font-extrabold text-gray-900">
        Planes y Precios
      </h2>
      <div className="mt-12 grid gap-8 grid-cols-1 md:grid-cols-3">
        {pricingPlans.map((plan, index) => (
          <motion.div
            key={index}
            className="border rounded-lg shadow-lg p-6 flex flex-col"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: index * 0.1 }}
            whileHover={{ scale: 1.05 }}
          >
            <h3 className="text-xl font-semibold text-gray-900">{plan.name}</h3>
            <p className="mt-4 text-4xl font-bold text-gray-900">
              {plan.price}
              <span className="text-base font-normal">/mes</span>
            </p>
            <ul className="mt-6 space-y-4 flex-grow">
              {plan.features.map((feature, featureIndex) => (
                <li key={featureIndex} className="flex items-start">
                  <Check className="h-6 w-6 text-green-500 flex-shrink-0" />
                  <span className="ml-3 text-base text-gray-500">
                    {feature}
                  </span>
                </li>
              ))}
            </ul>
            <Button className="mt-8 w-full">Elegir plan</Button>
          </motion.div>
        ))}
      </div>
    </div>
  );
};
