import React, { useState, useRef, useEffect } from "react";
import { Button } from "@components/ui/button";
import { Input } from "@components/ui/input";
import { ScrollArea } from "@components/ui/scroll-area";
import { Avatar, AvatarFallback, AvatarImage } from "@components/ui/avatar";
import {
  Send,
  Plus,
  Paperclip,
  Mic,
  File,
  Image,
  ArrowLeft,
  Check,
} from "lucide-react";
import Navbar from "@components/narbar/narbar";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@components/ui/dropdown-menu";

interface Message {
  id: string;
  sender: string;
  content: string;
  timestamp: Date;
  type: "text" | "file" | "audio";
  fileUrl?: string;
  status: "sent" | "delivered" | "read";
}

interface Conversation {
  id: string;
  name: string;
  lastMessage: string;
  avatar: string;
  isOnline: boolean;
  unreadCount: number;
}

const initialConversations: Conversation[] = [
  {
    id: "1",
    name: "Juan Pérez",
    lastMessage: "Hola, ¿cómo estás?",
    avatar: "/avatar1.png",
    isOnline: true,
    unreadCount: 2,
  },
  {
    id: "2",
    name: "María García",
    lastMessage: "¿Nos vemos mañana?",
    avatar: "/avatar2.png",
    isOnline: false,
    unreadCount: 0,
  },
  {
    id: "3",
    name: "Carlos Rodríguez",
    lastMessage: "Gracias por la información",
    avatar: "/avatar3.png",
    isOnline: true,
    unreadCount: 1,
  },
];

const initialMessages: Message[] = [
  {
    id: "1",
    sender: "Juan Pérez",
    content: "Hola, ¿cómo estás?",
    timestamp: new Date("2023-08-30T10:00:00"),
    type: "text",
    status: "read",
  },
  {
    id: "2",
    sender: "Tú",
    content: "¡Hola Juan! Estoy bien, gracias. ¿Y tú?",
    timestamp: new Date("2023-08-30T10:05:00"),
    type: "text",
    status: "read",
  },
  {
    id: "3",
    sender: "Juan Pérez",
    content: "Todo bien por aquí. ¿Qué tal va el proyecto?",
    timestamp: new Date("2023-08-30T10:10:00"),
    type: "text",
    status: "delivered",
  },
];

export default function ChatView() {
  const [conversations, setConversations] =
    useState<Conversation[]>(initialConversations);
  const [messages, setMessages] = useState<Message[]>(initialMessages);
  const [currentConversation, setCurrentConversation] =
    useState<Conversation | null>(null);
  const [newMessage, setNewMessage] = useState("");
  const [isRecording, setIsRecording] = useState(false);
  const [isMobileView, setIsMobileView] = useState(false);
  const [showConversations, setShowConversations] = useState(true);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const messagesEndRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth < 768);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  const sendMessage = (
    content: string,
    type: "text" | "file" | "audio",
    fileUrl?: string
  ) => {
    const message: Message = {
      id: Date.now().toString(),
      sender: "Tú",
      content,
      timestamp: new Date(),
      type,
      fileUrl,
      status: "sent",
    };

    setMessages([...messages, message]);
    setNewMessage("");

    // Simulate message delivery and read status
    setTimeout(() => {
      setMessages((prevMessages) =>
        prevMessages.map((msg) =>
          msg.id === message.id ? { ...msg, status: "delivered" } : msg
        )
      );
    }, 1000);

    setTimeout(() => {
      setMessages((prevMessages) =>
        prevMessages.map((msg) =>
          msg.id === message.id ? { ...msg, status: "read" } : msg
        )
      );
    }, 2000);
  };

  const handleSendMessage = () => {
    if (newMessage.trim() !== "") {
      sendMessage(newMessage, "text");
    }
  };

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const fileUrl = e.target?.result as string;
        sendMessage(file.name, "file", fileUrl);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleRecordAudio = () => {
    setIsRecording(!isRecording);
    // Aquí iría la lógica para grabar audio
    if (isRecording) {
      // Finalizar grabación y enviar audio
      sendMessage("Audio message", "audio", "audio_url_here");
    }
  };

  const handleConversationClick = (conversation: Conversation) => {
    setCurrentConversation(conversation);
    setShowConversations(false);
    // Reset unread count
    setConversations((prevConversations) =>
      prevConversations.map((conv) =>
        conv.id === conversation.id ? { ...conv, unreadCount: 0 } : conv
      )
    );
  };

  const renderConversationList = () => (
    <div className="w-full md:w-1/4 border-r">
      <div className="p-4 border-b flex justify-between items-center">
        <h2 className="text-xl font-semibold">Chats</h2>
        <Button
          onClick={() => {
            /* Lógica para nueva conversación */
          }}
          variant="ghost"
          size="icon"
        >
          <Plus className="h-5 w-5" />
        </Button>
      </div>
      <ScrollArea className="h-[calc(100vh-16rem)]">
        {conversations.map((conv) => (
          <div
            key={conv.id}
            className={`flex items-center p-4 hover:bg-gray-100 cursor-pointer ${
              currentConversation?.id === conv.id ? "bg-gray-100" : ""
            }`}
            onClick={() => handleConversationClick(conv)}
          >
            <div className="relative">
              <Avatar className="h-12 w-12">
                <AvatarImage src={conv.avatar} alt={conv.name} />
                <AvatarFallback>{conv.name.charAt(0)}</AvatarFallback>
              </Avatar>
              {conv.isOnline && (
                <span className="absolute bottom-0 right-0 block h-3 w-3 rounded-full bg-green-400 ring-2 ring-white"></span>
              )}
            </div>
            <div className="ml-4 flex-1 overflow-hidden">
              <div className="flex justify-between items-center">
                <p className="font-semibold">{conv.name}</p>
                {conv.unreadCount > 0 && (
                  <span className="bg-blue-500 text-white text-xs font-bold px-2 py-1 rounded-full">
                    {conv.unreadCount}
                  </span>
                )}
              </div>
              <p className="text-sm text-gray-500 truncate">
                {conv.lastMessage}
              </p>
            </div>
          </div>
        ))}
      </ScrollArea>
    </div>
  );

  const renderChatWindow = () => (
    <div className="flex-1 flex flex-col h-full">
      {currentConversation && (
        <>
          <div className="p-4 border-b flex justify-between items-center">
            {isMobileView && (
              <Button
                variant="ghost"
                size="icon"
                onClick={() => setShowConversations(true)}
              >
                <ArrowLeft className="h-5 w-5" />
              </Button>
            )}
            <div className="flex items-center">
              <Avatar className="h-10 w-10 mr-4">
                <AvatarImage
                  src={currentConversation.avatar}
                  alt={currentConversation.name}
                />
                <AvatarFallback>
                  {currentConversation.name.charAt(0)}
                </AvatarFallback>
              </Avatar>
              <div>
                <h2 className="text-xl font-semibold">
                  {currentConversation.name}
                </h2>
                <p className="text-sm text-gray-500">
                  {currentConversation.isOnline ? "En línea" : "Desconectado"}
                </p>
              </div>
            </div>
          </div>
          <ScrollArea className="flex-1 p-4">
            {messages.map((message) => (
              <div
                key={message.id}
                className={`mb-4 ${
                  message.sender === "Tú" ? "text-right" : "text-left"
                }`}
              >
                <div
                  className={`inline-block p-2 rounded-lg ${
                    message.sender === "Tú"
                      ? "bg-blue-500 text-white"
                      : "bg-gray-200 text-gray-800"
                  }`}
                >
                  {message.type === "text" && <p>{message.content}</p>}
                  {message.type === "file" && (
                    <div className="flex items-center">
                      <File className="mr-2 h-4 w-4" />
                      <a href={message.fileUrl} download className="underline">
                        {message.content}
                      </a>
                    </div>
                  )}
                  {message.type === "audio" && (
                    <div className="flex items-center">
                      <Mic className="mr-2 h-4 w-4" />
                      <audio src={message.fileUrl} controls />
                    </div>
                  )}
                  <p className="text-xs mt-1 opacity-70">
                    {message.timestamp.toLocaleTimeString([], {
                      hour: "2-digit",
                      minute: "2-digit",
                    })}
                  </p>
                </div>
                {message.sender === "Tú" && (
                  <span className="text-xs text-gray-500 ml-2">
                    {message.status === "sent" && (
                      <Check className="inline h-4 w-4" />
                    )}
                    {message.status === "delivered" && (
                      <Check className="inline h-4 w-4" />
                    )}
                    {message.status === "read" && (
                      <Check className="inline h-4 w-4 text-blue-500" />
                    )}
                  </span>
                )}
              </div>
            ))}
            <div ref={messagesEndRef} />
          </ScrollArea>
          <div className="p-4 border-t">
            <div className="flex space-x-2">
              <Input
                value={newMessage}
                onChange={(e) => setNewMessage(e.target.value)}
                placeholder="Escribe un mensaje..."
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    handleSendMessage();
                  }
                }}
              />
              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <Button variant="outline">
                    <Paperclip className="h-4 w-4" />
                  </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent>
                  <DropdownMenuItem
                    onSelect={() => fileInputRef.current?.click()}
                  >
                    <File className="mr-2 h-4 w-4" />
                    <span>Enviar archivo</span>
                  </DropdownMenuItem>
                  <DropdownMenuItem
                    onSelect={() => fileInputRef.current?.click()}
                  >
                    <Image className="mr-2 h-4 w-4" />
                    <span>Enviar imagen</span>
                  </DropdownMenuItem>
                </DropdownMenuContent>
              </DropdownMenu>
              <input
                type="file"
                ref={fileInputRef}
                className="hidden"
                onChange={handleFileUpload}
              />
              <Button
                variant={isRecording ? "destructive" : "outline"}
                onClick={handleRecordAudio}
              >
                <Mic className="h-4 w-4" />
              </Button>
              <Button onClick={handleSendMessage}>
                <Send className="h-4 w-4" />
              </Button>
            </div>
          </div>
        </>
      )}
    </div>
  );

  return (
    <div className="min-h-screen bg-gray-100">
      <Navbar />
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="bg-white rounded-lg shadow-lg overflow-hidden h-[calc(100vh-12rem)]">
          <div className="flex h-full">
            {isMobileView ? (
              showConversations ? (
                renderConversationList()
              ) : (
                renderChatWindow()
              )
            ) : (
              <>
                {renderConversationList()}
                {renderChatWindow()}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
