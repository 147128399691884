import { useState } from "react";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@components/ui/tabs";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@components/ui/card";
import { Input } from "@components/ui/input";
import { Label } from "@components/ui/label";
import { Button } from "@components/ui/button";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@components/ui/select";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@components/ui/table";
import { Badge } from "@components/ui/badge";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@components/ui/dialog";
import { Barcode, Package, AlertTriangle } from "lucide-react";
import Narbar from "@components/narbar/narbar";

export default function Inventoy() {
  const [activeTab, setActiveTab] = useState("productos");
  const [productos, setProductos] = useState([
    {
      id: 1,
      nombre: "Producto A",
      sku: "SKU001",
      cantidad: 100,
      precio: 50000,
      categoria: "Electrónicos",
    },
    {
      id: 2,
      nombre: "Producto B",
      sku: "SKU002",
      cantidad: 50,
      precio: 30000,
      categoria: "Ropa",
    },
    {
      id: 3,
      nombre: "Producto C",
      sku: "SKU003",
      cantidad: 75,
      precio: 40000,
      categoria: "Hogar",
    },
  ]);
  const [nuevoProducto, setNuevoProducto] = useState({
    nombre: "",
    sku: "",
    cantidad: 0,
    precio: 0,
    categoria: "",
  });

  const handleAddProduct = () => {
    setProductos([
      ...productos,
      { ...nuevoProducto, id: productos.length + 1 },
    ]);
    setNuevoProducto({
      nombre: "",
      sku: "",
      cantidad: 0,
      precio: 0,
      categoria: "",
    });
  };

  const getStockStatus = (cantidad: number) => {
    if (cantidad > 100) return <Badge className="bg-green-500">Alto</Badge>;
    if (cantidad > 50) return <Badge className="bg-yellow-500">Medio</Badge>;
    return <Badge className="bg-red-500">Bajo</Badge>;
  };

  return (
    <div>
      <Narbar />
      <div className="container mx-auto py-10">
        <h1 className="text-3xl font-bold mb-5">Gestión de Inventario</h1>
        <Tabs value={activeTab} onValueChange={setActiveTab} className="w-full">
          <TabsList className="grid w-full grid-cols-3">
            <TabsTrigger value="productos">Productos</TabsTrigger>
            <TabsTrigger value="movimientos">Movimientos</TabsTrigger>
            <TabsTrigger value="reportes">Reportes</TabsTrigger>
          </TabsList>
          <TabsContent value="productos">
            <Card>
              <CardHeader>
                <CardTitle>Lista de Productos</CardTitle>
                <CardDescription>
                  Gestiona tu inventario de productos aquí.
                </CardDescription>
              </CardHeader>
              <CardContent>
                <Table>
                  <TableHeader>
                    <TableRow>
                      <TableHead>Nombre</TableHead>
                      <TableHead>SKU</TableHead>
                      <TableHead>Cantidad</TableHead>
                      <TableHead>Precio</TableHead>
                      <TableHead>Categoría</TableHead>
                      <TableHead>Estado de Stock</TableHead>
                      <TableHead>Acciones</TableHead>
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    {productos.map((producto) => (
                      <TableRow key={producto.id}>
                        <TableCell>{producto.nombre}</TableCell>
                        <TableCell>{producto.sku}</TableCell>
                        <TableCell>{producto.cantidad}</TableCell>
                        <TableCell>
                          ${producto.precio.toLocaleString()}
                        </TableCell>
                        <TableCell>{producto.categoria}</TableCell>
                        <TableCell>
                          {getStockStatus(producto.cantidad)}
                        </TableCell>
                        <TableCell>
                          <Button variant="outline" size="sm">
                            Editar
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </CardContent>
              <CardFooter>
                <Dialog>
                  <DialogTrigger asChild>
                    <Button>Añadir Producto</Button>
                  </DialogTrigger>
                  <DialogContent>
                    <DialogHeader>
                      <DialogTitle>Añadir Nuevo Producto</DialogTitle>
                      <DialogDescription>
                        Ingrese los detalles del nuevo producto aquí.
                      </DialogDescription>
                    </DialogHeader>
                    <div className="grid gap-4 py-4">
                      <div className="grid grid-cols-4 items-center gap-4">
                        <Label htmlFor="nombre" className="text-right">
                          Nombre
                        </Label>
                        <Input
                          id="nombre"
                          value={nuevoProducto.nombre}
                          onChange={(e) =>
                            setNuevoProducto({
                              ...nuevoProducto,
                              nombre: e.target.value,
                            })
                          }
                          className="col-span-3"
                        />
                      </div>
                      <div className="grid grid-cols-4 items-center gap-4">
                        <Label htmlFor="sku" className="text-right">
                          SKU
                        </Label>
                        <Input
                          id="sku"
                          value={nuevoProducto.sku}
                          onChange={(e) =>
                            setNuevoProducto({
                              ...nuevoProducto,
                              sku: e.target.value,
                            })
                          }
                          className="col-span-3"
                        />
                      </div>
                      <div className="grid grid-cols-4 items-center gap-4">
                        <Label htmlFor="cantidad" className="text-right">
                          Cantidad
                        </Label>
                        <Input
                          id="cantidad"
                          type="number"
                          value={nuevoProducto.cantidad}
                          onChange={(e) =>
                            setNuevoProducto({
                              ...nuevoProducto,
                              cantidad: parseInt(e.target.value),
                            })
                          }
                          className="col-span-3"
                        />
                      </div>
                      <div className="grid grid-cols-4 items-center gap-4">
                        <Label htmlFor="precio" className="text-right">
                          Precio
                        </Label>
                        <Input
                          id="precio"
                          type="number"
                          value={nuevoProducto.precio}
                          onChange={(e) =>
                            setNuevoProducto({
                              ...nuevoProducto,
                              precio: parseFloat(e.target.value),
                            })
                          }
                          className="col-span-3"
                        />
                      </div>
                      <div className="grid grid-cols-4 items-center gap-4">
                        <Label htmlFor="categoria" className="text-right">
                          Categoría
                        </Label>
                        <Select
                          value={nuevoProducto.categoria}
                          onValueChange={(value) =>
                            setNuevoProducto({
                              ...nuevoProducto,
                              categoria: value,
                            })
                          }
                        >
                          <SelectTrigger className="col-span-3">
                            <SelectValue placeholder="Seleccionar categoría" />
                          </SelectTrigger>
                          <SelectContent>
                            <SelectItem value="Electrónicos">
                              Electrónicos
                            </SelectItem>
                            <SelectItem value="Ropa">Ropa</SelectItem>
                            <SelectItem value="Hogar">Hogar</SelectItem>
                            <SelectItem value="Alimentos">Alimentos</SelectItem>
                          </SelectContent>
                        </Select>
                      </div>
                    </div>
                    <DialogFooter>
                      <Button onClick={handleAddProduct}>
                        Guardar Producto
                      </Button>
                    </DialogFooter>
                  </DialogContent>
                </Dialog>
              </CardFooter>
            </Card>
          </TabsContent>
          <TabsContent value="movimientos">
            <Card>
              <CardHeader>
                <CardTitle>Movimientos de Inventario</CardTitle>
                <CardDescription>
                  Registra entradas y salidas de productos.
                </CardDescription>
              </CardHeader>
              <CardContent>
                <div className="space-y-4">
                  <div className="grid grid-cols-2 gap-4">
                    <div>
                      <Label htmlFor="tipoMovimiento">Tipo de Movimiento</Label>
                      <Select>
                        <SelectTrigger id="tipoMovimiento">
                          <SelectValue placeholder="Seleccionar tipo" />
                        </SelectTrigger>
                        <SelectContent>
                          <SelectItem value="entrada">Entrada</SelectItem>
                          <SelectItem value="salida">Salida</SelectItem>
                        </SelectContent>
                      </Select>
                    </div>
                    <div>
                      <Label htmlFor="productoMovimiento">Producto</Label>
                      <Select>
                        <SelectTrigger id="productoMovimiento">
                          <SelectValue placeholder="Seleccionar producto" />
                        </SelectTrigger>
                        <SelectContent>
                          {productos.map((producto) => (
                            <SelectItem
                              key={producto.id}
                              value={producto.id.toString()}
                            >
                              {producto.nombre}
                            </SelectItem>
                          ))}
                        </SelectContent>
                      </Select>
                    </div>
                  </div>
                  <div className="grid grid-cols-2 gap-4">
                    <div>
                      <Label htmlFor="cantidadMovimiento">Cantidad</Label>
                      <Input
                        id="cantidadMovimiento"
                        type="number"
                        placeholder="Cantidad"
                      />
                    </div>
                    <div>
                      <Label htmlFor="fechaMovimiento">Fecha</Label>
                      <Input id="fechaMovimiento" type="date" />
                    </div>
                  </div>
                  <div>
                    <Label htmlFor="motivoMovimiento">Motivo</Label>
                    <Input
                      id="motivoMovimiento"
                      placeholder="Motivo del movimiento"
                    />
                  </div>
                  <Button>Registrar Movimiento</Button>
                </div>
              </CardContent>
            </Card>
          </TabsContent>
          <TabsContent value="reportes">
            <Card>
              <CardHeader>
                <CardTitle>Reportes de Inventario</CardTitle>
                <CardDescription>
                  Genera informes sobre el estado de tu inventario.
                </CardDescription>
              </CardHeader>
              <CardContent>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                  <Card>
                    <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
                      <CardTitle className="text-sm font-medium">
                        Total de Productos
                      </CardTitle>
                      <Package className="h-4 w-4 text-muted-foreground" />
                    </CardHeader>
                    <CardContent>
                      <div className="text-2xl font-bold">
                        {productos.length}
                      </div>
                    </CardContent>
                  </Card>
                  <Card>
                    <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
                      <CardTitle className="text-sm font-medium">
                        Valor Total del Inventario
                      </CardTitle>
                      <Barcode className="h-4 w-4 text-muted-foreground" />
                    </CardHeader>
                    <CardContent>
                      <div className="text-2xl font-bold">
                        $
                        {productos
                          .reduce(
                            (total, producto) =>
                              total + producto.precio * producto.cantidad,
                            0
                          )
                          .toLocaleString()}
                      </div>
                    </CardContent>
                  </Card>
                  <Card>
                    <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
                      <CardTitle className="text-sm font-medium">
                        Productos con Stock Bajo
                      </CardTitle>
                      <AlertTriangle className="h-4 w-4 text-muted-foreground" />
                    </CardHeader>
                    <CardContent>
                      <div className="text-2xl font-bold">
                        {productos.filter((p) => p.cantidad <= 50).length}
                      </div>
                    </CardContent>
                  </Card>
                </div>
              </CardContent>
              <CardFooter>
                <Button>Generar Reporte Detallado</Button>
              </CardFooter>
            </Card>
          </TabsContent>
        </Tabs>
      </div>
    </div>
  );
}
