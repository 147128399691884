import { Zap } from "lucide-react";

export const FooterHome = () => {
  return (
    <footer className="bg-gray-50">
      <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between items-center">
          <div className="flex items-center">
            <Zap className="h-8 w-auto text-yellow-400" />
            <span className="ml-2 text-xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-blue-600 to-green-400">
              ZapTrack
            </span>
          </div>
          <p className="text-center text-base text-gray-400">
            &copy; 2024 - ZapTrack. Todos los derechos reservados.
          </p>
        </div>
      </div>
    </footer>
  );
};
