import { useState } from "react";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@components/ui/tabs";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@components/ui/card";
import { Button } from "@components/ui/button";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@components/ui/select";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@components/ui/table";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@components/ui/dialog";
import { Input } from "@components/ui/input";
import { Label } from "@components/ui/label";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  LineChart,
  Line,
} from "recharts";
import { Download, TrendingUp } from "lucide-react";
import Narbar from "@components/narbar/narbar";

export default function FinancialReports() {
  const [activeTab, setActiveTab] = useState("balance");
  const [periodoSeleccionado, setPeriodoSeleccionado] = useState("2023-Q2");

  const balanceData = [
    { categoria: "Activos Corrientes", valor: 500000 },
    { categoria: "Activos No Corrientes", valor: 1000000 },
    { categoria: "Pasivos Corrientes", valor: 300000 },
    { categoria: "Pasivos No Corrientes", valor: 700000 },
    { categoria: "Patrimonio", valor: 500000 },
  ];

  const resultadosData = [
    { categoria: "Ingresos", valor: 1000000 },
    { categoria: "Costo de Ventas", valor: -600000 },
    { categoria: "Gastos Operativos", valor: -200000 },
    { categoria: "Otros Ingresos", valor: 50000 },
    { categoria: "Impuestos", valor: -50000 },
  ];

  const flujoCajaData = [
    { mes: "Ene", operativo: 50000, inversion: -20000, financiamiento: -10000 },
    { mes: "Feb", operativo: 60000, inversion: -15000, financiamiento: -10000 },
    { mes: "Mar", operativo: 55000, inversion: -25000, financiamiento: -10000 },
    { mes: "Abr", operativo: 70000, inversion: -30000, financiamiento: -10000 },
    { mes: "May", operativo: 65000, inversion: -20000, financiamiento: -10000 },
    { mes: "Jun", operativo: 80000, inversion: -35000, financiamiento: -10000 },
  ];

  const indicadoresFinancieros = [
    { nombre: "Liquidez Corriente", valor: 1.67 },
    { nombre: "Margen de Beneficio", valor: 0.2 },
    { nombre: "ROE", valor: 0.15 },
    { nombre: "Endeudamiento", valor: 0.67 },
  ];

  return (
    <div>
      <Narbar />
      <div className="container mx-auto py-6 px-4 sm:px-6 lg:px-8">
        <h1 className="text-2xl sm:text-3xl font-bold mb-6">
          Informes Financieros
        </h1>
        <div className="mb-6">
          <Select
            value={periodoSeleccionado}
            onValueChange={setPeriodoSeleccionado}
          >
            <SelectTrigger className="w-full sm:w-[180px]">
              <SelectValue placeholder="Seleccionar periodo" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="2023-Q2">2023 Q2</SelectItem>
              <SelectItem value="2023-Q1">2023 Q1</SelectItem>
              <SelectItem value="2022-Q4">2022 Q4</SelectItem>
              <SelectItem value="2022-Q3">2022 Q3</SelectItem>
            </SelectContent>
          </Select>
        </div>
        <Tabs value={activeTab} onValueChange={setActiveTab} className="w-full">
          <TabsList className="grid w-full grid-cols-2 md:grid-cols-4 mb-6">
            <TabsTrigger value="balance">Balance</TabsTrigger>
            <TabsTrigger value="resultados">Resultados</TabsTrigger>
            <TabsTrigger value="flujo-caja">Flujo de Caja</TabsTrigger>
            <TabsTrigger value="indicadores">Indicadores</TabsTrigger>
          </TabsList>
          <TabsContent value="balance">
            <Card>
              <CardHeader>
                <CardTitle className="text-xl">Balance General</CardTitle>
                <CardDescription>
                  Resumen de activos, pasivos y patrimonio de la empresa.
                </CardDescription>
              </CardHeader>
              <CardContent>
                <div className="h-[300px] sm:h-[400px] mb-6">
                  <ResponsiveContainer width="100%" height="100%">
                    <BarChart data={balanceData} layout="vertical">
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis type="number" />
                      <YAxis dataKey="categoria" type="category" width={150} />
                      <Tooltip />
                      <Legend />
                      <Bar dataKey="valor" fill="#8884d8" />
                    </BarChart>
                  </ResponsiveContainer>
                </div>
                <div className="overflow-x-auto">
                  <Table>
                    <TableHeader>
                      <TableRow>
                        <TableHead>Categoría</TableHead>
                        <TableHead className="text-right">Valor</TableHead>
                      </TableRow>
                    </TableHeader>
                    <TableBody>
                      {balanceData.map((item) => (
                        <TableRow key={item.categoria}>
                          <TableCell>{item.categoria}</TableCell>
                          <TableCell className="text-right">
                            ${item.valor.toLocaleString()}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </div>
              </CardContent>
              <CardFooter>
                <Button className="w-full sm:w-auto">
                  <Download className="mr-2 h-4 w-4" />
                  Descargar Informe
                </Button>
              </CardFooter>
            </Card>
          </TabsContent>
          <TabsContent value="resultados">
            <Card>
              <CardHeader>
                <CardTitle className="text-xl">Estado de Resultados</CardTitle>
                <CardDescription>
                  Resumen de ingresos, gastos y beneficios de la empresa.
                </CardDescription>
              </CardHeader>
              <CardContent>
                <div className="h-[300px] sm:h-[400px] mb-6">
                  <ResponsiveContainer width="100%" height="100%">
                    <BarChart data={resultadosData} layout="vertical">
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis type="number" />
                      <YAxis dataKey="categoria" type="category" width={150} />
                      <Tooltip />
                      <Legend />
                      <Bar dataKey="valor" fill="#82ca9d" />
                    </BarChart>
                  </ResponsiveContainer>
                </div>
                <div className="overflow-x-auto">
                  <Table>
                    <TableHeader>
                      <TableRow>
                        <TableHead>Categoría</TableHead>
                        <TableHead className="text-right">Valor</TableHead>
                      </TableRow>
                    </TableHeader>
                    <TableBody>
                      {resultadosData.map((item) => (
                        <TableRow key={item.categoria}>
                          <TableCell>{item.categoria}</TableCell>
                          <TableCell className="text-right">
                            ${item.valor.toLocaleString()}
                          </TableCell>
                        </TableRow>
                      ))}
                      <TableRow>
                        <TableCell className="font-bold">
                          Beneficio Neto
                        </TableCell>
                        <TableCell className="text-right font-bold">
                          $
                          {resultadosData
                            .reduce((sum, item) => sum + item.valor, 0)
                            .toLocaleString()}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </div>
              </CardContent>
              <CardFooter>
                <Button className="w-full sm:w-auto">
                  <Download className="mr-2 h-4 w-4" />
                  Descargar Informe
                </Button>
              </CardFooter>
            </Card>
          </TabsContent>
          <TabsContent value="flujo-caja">
            <Card>
              <CardHeader>
                <CardTitle className="text-xl">Flujo de Caja</CardTitle>
                <CardDescription>
                  Movimientos de efectivo de la empresa.
                </CardDescription>
              </CardHeader>
              <CardContent>
                <div className="h-[300px] sm:h-[400px] mb-6">
                  <ResponsiveContainer width="100%" height="100%">
                    <LineChart data={flujoCajaData}>
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="mes" />
                      <YAxis />
                      <Tooltip />
                      <Legend />
                      <Line
                        type="monotone"
                        dataKey="operativo"
                        stroke="#8884d8"
                      />
                      <Line
                        type="monotone"
                        dataKey="inversion"
                        stroke="#82ca9d"
                      />
                      <Line
                        type="monotone"
                        dataKey="financiamiento"
                        stroke="#ffc658"
                      />
                    </LineChart>
                  </ResponsiveContainer>
                </div>
                <div className="overflow-x-auto">
                  <Table>
                    <TableHeader>
                      <TableRow>
                        <TableHead>Mes</TableHead>
                        <TableHead className="text-right">Operativo</TableHead>
                        <TableHead className="text-right">Inversión</TableHead>
                        <TableHead className="text-right">
                          Financiamiento
                        </TableHead>
                      </TableRow>
                    </TableHeader>
                    <TableBody>
                      {flujoCajaData.map((item) => (
                        <TableRow key={item.mes}>
                          <TableCell>{item.mes}</TableCell>
                          <TableCell className="text-right">
                            ${item.operativo.toLocaleString()}
                          </TableCell>
                          <TableCell className="text-right">
                            ${item.inversion.toLocaleString()}
                          </TableCell>
                          <TableCell className="text-right">
                            ${item.financiamiento.toLocaleString()}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </div>
              </CardContent>
              <CardFooter>
                <Button className="w-full sm:w-auto">
                  <Download className="mr-2 h-4 w-4" />
                  Descargar Informe
                </Button>
              </CardFooter>
            </Card>
          </TabsContent>
          <TabsContent value="indicadores">
            <Card>
              <CardHeader>
                <CardTitle className="text-xl">
                  Indicadores Financieros
                </CardTitle>
                <CardDescription>
                  Métricas clave de rendimiento financiero.
                </CardDescription>
              </CardHeader>
              <CardContent>
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
                  {indicadoresFinancieros.map((indicador) => (
                    <Card key={indicador.nombre}>
                      <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
                        <CardTitle className="text-sm font-medium">
                          {indicador.nombre}
                        </CardTitle>
                        <TrendingUp className="h-4 w-4 text-muted-foreground" />
                      </CardHeader>
                      <CardContent>
                        <div className="text-2xl font-bold">
                          {indicador.valor.toFixed(2)}
                        </div>
                      </CardContent>
                    </Card>
                  ))}
                </div>
              </CardContent>
              <CardFooter className="flex flex-col sm:flex-row gap-4">
                <Dialog>
                  <DialogTrigger asChild>
                    <Button variant="outline" className="w-full sm:w-auto">
                      Añadir Indicador Personalizado
                    </Button>
                  </DialogTrigger>
                  <DialogContent className="sm:max-w-[425px]">
                    <DialogHeader>
                      <DialogTitle>Añadir Indicador</DialogTitle>
                      <DialogDescription>
                        Crea un nuevo indicador financiero personalizado.
                      </DialogDescription>
                    </DialogHeader>
                    <div className="grid gap-4 py-4">
                      <div className="grid grid-cols-4 items-center gap-4">
                        <Label htmlFor="nombre" className="text-right">
                          Nombre
                        </Label>
                        <Input id="nombre" className="col-span-3" />
                      </div>
                      <div className="grid grid-cols-4 items-center gap-4">
                        <Label htmlFor="formula" className="text-right">
                          Fórmula
                        </Label>
                        <Input id="formula" className="col-span-3" />
                      </div>
                    </div>
                    <DialogFooter>
                      <Button type="submit">Guardar Indicador</Button>
                    </DialogFooter>
                  </DialogContent>
                </Dialog>
                <Button className="w-full sm:w-auto">
                  <Download className="mr-2 h-4 w-4" />
                  Descargar Informe
                </Button>
              </CardFooter>
            </Card>
          </TabsContent>
        </Tabs>
      </div>
    </div>
  );
}
