import { useNavigate } from "react-router-dom";
import { useCallback } from "react";

export const useLogout = () => {
  const navigate = useNavigate();

  const logout = useCallback(() => {
    localStorage.removeItem("user");
    localStorage.clear();
    navigate("/", { replace: true });
    window.location.reload();
  }, [navigate]);

  return logout;
};
