import { Board } from "./types";

export const initialBoards: Board[] = [
  {
    id: "board-1",
    title: "Proyecto Principal",
    type: "group",
    columns: {
      "column-1": {
        id: "column-1",
        title: "Por hacer",
        tasks: [
          {
            id: "task-1",
            content: "Crear diseño de la aplicación",
            startDate: "2023-06-01",
            endDate: "2023-06-07",
            description: "Diseñar la interfaz de usuario de la aplicación",
            assignee: "Ana",
            color: "#ffffff",
            tags: [{ id: "tag-1", name: "Diseño", color: "#ff9900" }],
          },
          {
            id: "task-2",
            content: "Implementar autenticación",
            startDate: "2023-06-08",
            endDate: "2023-06-14",
            description: "Implementar sistema de login y registro",
            assignee: "Carlos",
            color: "#ffffff",
            tags: [{ id: "tag-2", name: "Backend", color: "#33cc33" }],
          },
        ],
      },
      "column-2": {
        id: "column-2",
        title: "En progreso",
        tasks: [
          {
            id: "task-3",
            content: "Desarrollar API REST",
            startDate: "2023-06-01",
            endDate: "2023-06-21",
            description: "Crear endpoints para la API del backend",
            assignee: "María",
            color: "#ffffff",
            tags: [{ id: "tag-3", name: "Backend", color: "#33cc33" }],
          },
        ],
      },
      "column-3": {
        id: "column-3",
        title: "Completado",
        tasks: [
          {
            id: "task-4",
            content: "Configurar entorno de desarrollo",
            startDate: "2023-05-25",
            endDate: "2023-05-31",
            description: "Preparar el entorno de desarrollo para el equipo",
            assignee: "Juan",
            color: "#ffffff",
            tags: [{ id: "tag-4", name: "DevOps", color: "#ff3399" }],
          },
        ],
      },
    },
    columnOrder: ["column-1", "column-2", "column-3"],
    backgroundColor: "#f0f4f8",
    backgroundImage: "",
  },
];
