/* eslint-disable @typescript-eslint/no-explicit-any */
export type HttpErrorHandler = (error: any) => any;

export const HttpErrorHandler: HttpErrorHandler = (error: any) => {
  if (error.message === "CANCEL") {
    return { message: "Request was cancelled", status: null };
  }

  const { response } = error ?? {};
  if (response) {
    const { data = {}, status = 500 } = response;
    return {
      message: data || data.message || "Error inesperado",
      status,
    };
  }

  return {
    message: "Error inesperado",
    status: 500,
  };
};

const service = {
  HttpErrorHandler,
};

export default service;
