import { useState, useEffect, useRef, RefObject } from "react";
import { Button } from "@components/ui/button";
import { Zap, Menu, X } from "lucide-react";
import {
  Sheet,
  SheetContent,
  SheetTrigger,
  SheetClose,
} from "@components/ui/sheet";
import { motion } from "framer-motion";
import { FooterHome } from "./footer";
import { FeaturesSection } from "./feature";
import { PricingSeccion } from "./Pricing";
import { SupportSeccion } from "./support";

export default function Component() {
  const [isOpen, setIsOpen] = useState(false);
  const [animateHeader, setAnimateHeader] = useState(false);

  const featuresRef = useRef<HTMLDivElement>(null);
  const pricingRef = useRef<HTMLDivElement>(null);
  const supportRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      setAnimateHeader(scrollPosition > 50);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const scrollToSection = (ref: RefObject<HTMLDivElement>) => {
    if (ref.current) {
      ref.current.scrollIntoView({ behavior: "smooth" });
      setIsOpen(false);
    }
  };

  return (
    <div className="flex flex-col min-h-screen">
      <motion.header
        className={`sticky top-0 bg-white shadow-sm z-10 transition-all ${
          animateHeader ? "py-2" : "py-4"
        }`}
        animate={{ height: animateHeader ? 60 : 80 }}
      >
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between items-center">
            <motion.div
              className="flex justify-start lg:w-0 lg:flex-1"
              whileHover={{ scale: 1.05 }}
            >
              <a href="/" className="flex items-center">
                <Zap className="h-8 w-auto sm:h-10 text-yellow-400" />
                <span className="ml-2 text-xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-blue-600 to-green-400">
                  ZapTrack
                </span>
              </a>
            </motion.div>
            <div className="-mr-2 -my-2 md:hidden">
              <Sheet open={isOpen} onOpenChange={setIsOpen}>
                <SheetTrigger asChild>
                  <Button variant="ghost" size="icon">
                    <Menu className="h-6 w-6" aria-hidden="true" />
                  </Button>
                </SheetTrigger>
                <SheetContent side="right" className="w-[300px] sm:w-[400px]">
                  <div className="flex flex-col h-full">
                    <div className="flex justify-between items-center mb-4">
                      <span className="text-xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-blue-600 to-green-400">
                        ZapTrack
                      </span>
                      <SheetClose asChild>
                        <Button variant="ghost" size="icon">
                          <X className="h-6 w-6" />
                        </Button>
                      </SheetClose>
                    </div>
                    <nav className="flex flex-col space-y-4">
                      <Button
                        variant="ghost"
                        onClick={() => scrollToSection(featuresRef)}
                      >
                        Características
                      </Button>
                      <Button
                        variant="ghost"
                        onClick={() => scrollToSection(pricingRef)}
                      >
                        Precios
                      </Button>
                      <Button
                        variant="ghost"
                        onClick={() => scrollToSection(supportRef)}
                      >
                        Soporte
                      </Button>
                    </nav>
                    <div className="mt-auto">
                      <Button variant="outline" className="w-full mb-2" asChild>
                        <a href="/login">Iniciar sesión</a>
                      </Button>
                      <Button
                        className="w-full bg-gradient-to-r from-blue-600 to-green-400 hover:from-blue-700 hover:to-green-500"
                        asChild
                      >
                        <a href="/register">Registrarse</a>
                      </Button>
                    </div>
                  </div>
                </SheetContent>
              </Sheet>
            </div>
            <nav className="hidden md:flex space-x-10">
              <button
                onClick={() => scrollToSection(featuresRef)}
                className="text-base font-medium text-gray-500 hover:text-gray-900"
              >
                Características
              </button>
              <button
                onClick={() => scrollToSection(pricingRef)}
                className="text-base font-medium text-gray-500 hover:text-gray-900"
              >
                Precios
              </button>
              <button
                onClick={() => scrollToSection(supportRef)}
                className="text-base font-medium text-gray-500 hover:text-gray-900"
              >
                Soporte
              </button>
            </nav>
            <div className="hidden md:flex items-center justify-end md:flex-1 lg:w-0">
              <a
                href="/login"
                className="whitespace-nowrap text-base font-medium text-gray-500 hover:text-gray-900"
              >
                Iniciar sesión
              </a>
              <motion.a
                href="/register"
                className="ml-8 whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-gradient-to-r from-blue-600 to-green-400 hover:from-blue-700 hover:to-green-500"
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                Registrarse
              </motion.a>
            </div>
          </div>
        </div>
      </motion.header>

      <main className="flex-grow">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
          <motion.div
            className="text-center"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            <h1 className="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl">
              <span className="block">Gestiona tu negocio con</span>
              <span className="block bg-clip-text text-transparent bg-gradient-to-r from-blue-600 to-green-400">
                ZapTrack
              </span>
            </h1>
            <p className="mt-3 max-w-md mx-auto text-base text-gray-500 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">
              Organiza, comparte y accede a tus archivos, gestiona tu equipo, y
              lleva tu negocio al siguiente nivel con nuestra solución todo en
              uno.
            </p>
            <div className="mt-5 max-w-md mx-auto sm:flex sm:justify-center md:mt-8">
              <motion.div
                className="rounded-md shadow"
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                <Button className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-gradient-to-r from-blue-600 to-green-400 hover:from-blue-700 hover:to-green-500 md:py-4 md:text-lg md:px-10">
                  Comenzar
                </Button>
              </motion.div>
              <motion.div
                className="mt-3 rounded-md shadow sm:mt-0 sm:ml-3"
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                <Button
                  variant="outline"
                  className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-blue-600 bg-white hover:bg-gray-50 md:py-4 md:text-lg md:px-10"
                >
                  Saber más
                </Button>
              </motion.div>
            </div>
          </motion.div>
          <section ref={featuresRef} className="py-16">
            <FeaturesSection />
          </section>

          <section ref={pricingRef} className="py-16 bg-gray-50">
            <PricingSeccion />
          </section>

          <section ref={supportRef} className="py-16">
            <SupportSeccion />
          </section>
        </div>
      </main>
      <FooterHome />
    </div>
  );
}
