import { Button } from "@components/ui/button";
import {
  Grid,
  Calendar,
  MessageSquare,
  Clipboard,
  Activity,
  Menu,
  LogOut,
  Settings,
  User,
  MoreVertical,
  Folder,
  Users,
  DollarSign,
} from "lucide-react";
import { Avatar, AvatarFallback, AvatarImage } from "@components/ui/avatar";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@components/ui/dropdown-menu";
import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
  SheetFooter,
} from "@components/ui/sheet";
import { useNavigate } from "react-router-dom";
import { useLogout } from "./use_logout";

export const NavbarMovile = () => {
  const navigate = useNavigate();
  const logout = useLogout();

  const handleNavigate = (nav: string) => {
    navigate(nav);
  };

  const Picture =
    "https://avatars.githubusercontent.com/u/141464312?s=400&u=3f2d88d3b5f3f5750243e0eb361ce472774485fb&v=4";

  return (
    <Sheet>
      <SheetTrigger asChild>
        <Button variant="ghost" size="icon" className="md:hidden">
          <Menu className="h-6 w-6" />
        </Button>
      </SheetTrigger>
      <SheetContent side="left" className="w-[300px] sm:w-[400px]">
        <SheetHeader>
          <SheetTitle>Menú</SheetTitle>
          <SheetDescription>
            Accede a todas las funciones de ZapTrack
          </SheetDescription>
        </SheetHeader>
        <div className="mt-4 space-y-4">
          <Button
            variant="ghost"
            className="w-full justify-start"
            onClick={() => handleNavigate("/dashboard")}
          >
            <Folder className="h-5 w-5 mr-2" />
            Archivos
          </Button>
          <Button
            variant="ghost"
            className="w-full justify-start"
            onClick={() => handleNavigate("/board")}
          >
            <Grid className="h-5 w-5 mr-2" />
            Tablero
          </Button>
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="ghost" className="w-full justify-start">
                <Users className="h-5 w-5 mr-2" />
                RRHH
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent>
              <DropdownMenuItem
                onSelect={() => handleNavigate("/rrhh/personal")}
              >
                Manejo de Personal
              </DropdownMenuItem>
              <DropdownMenuItem
                onSelect={() => handleNavigate("/rrhh/reclutamiento")}
              >
                Reclutamiento
              </DropdownMenuItem>
              <DropdownMenuItem
                onSelect={() => handleNavigate("/rrhh/capacitacion")}
              >
                Capacitación
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="ghost" className="w-full justify-start">
                <DollarSign className="h-5 w-5 mr-2" />
                Contabilidad
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent>
              <DropdownMenuItem
                onSelect={() => handleNavigate("/contabilidad/facturacion")}
              >
                Facturación
              </DropdownMenuItem>
              <DropdownMenuItem
                onSelect={() => handleNavigate("/contabilidad/impuestos")}
              >
                Impuestos
              </DropdownMenuItem>
              <DropdownMenuItem
                onSelect={() => handleNavigate("/contabilidad/informes")}
              >
                Informes Financieros
              </DropdownMenuItem>
              <DropdownMenuItem
                onSelect={() => handleNavigate("/contabilidad/inventario")}
              >
                Inventario
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
          <Button
            variant="ghost"
            className="w-full justify-start"
            onClick={() => handleNavigate("/calendar")}
          >
            <Calendar className="h-5 w-5 mr-2" />
            Calendario
          </Button>
          <Button
            variant="ghost"
            className="w-full justify-start"
            onClick={() => handleNavigate("/chat")}
          >
            <MessageSquare className="h-5 w-5 mr-2" />
            Chat
          </Button>
          <Button
            variant="ghost"
            className="w-full justify-start"
            onClick={() => handleNavigate("/notes")}
          >
            <Clipboard className="h-5 w-5 mr-2" />
            Notas
          </Button>
          <Button
            variant="ghost"
            className="w-full justify-start"
            onClick={() => handleNavigate("/activities")}
          >
            <Activity className="h-5 w-5 mr-2" />
            Actividades
          </Button>
        </div>
        <SheetFooter className="absolute bottom-0 left-0 right-0 p-4 border-t">
          <div className="flex items-center space-x-4">
            <Avatar>
              <AvatarImage src={Picture} alt="@usuario" />
              <AvatarFallback>CP</AvatarFallback>
            </Avatar>
            <div className="flex-1">
              <p className="text-sm font-medium">Cesar Pereira</p>
              <p className="text-xs text-gray-500">cesarandresdev@gmail.com</p>
            </div>
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button variant="ghost" size="icon">
                  <MoreVertical className="h-4 w-4" />
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent align="end">
                <DropdownMenuItem>
                  <User className="mr-2 h-4 w-4" />
                  <span>Perfil</span>
                </DropdownMenuItem>
                <DropdownMenuItem>
                  <Settings className="mr-2 h-4 w-4" />
                  <span>Configuración</span>
                </DropdownMenuItem>
                <DropdownMenuItem onClick={logout}>
                  <LogOut className="mr-2 h-4 w-4" />
                  <span>Cerrar sesión</span>
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
        </SheetFooter>
      </SheetContent>
    </Sheet>
  );
};
