import { Button } from "@components/ui/button";
import { useNavigate } from "react-router-dom";

import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@components/ui/card";
import { Zap, Rocket, Book, Settings } from "lucide-react";

export default function Bienvenida() {
  const navigate = useNavigate();

  const handleNavigate = (nav: string) => {
    navigate(nav);
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-400 to-blue-600 flex items-center justify-center p-4">
      <Card className="w-full max-w-2xl bg-white/90 backdrop-blur-sm">
        <CardHeader className="text-center">
          <CardTitle className="text-3xl font-bold text-blue-900 flex items-center justify-center gap-2">
            <Zap className="w-8 h-8 text-yellow-400" />
            Bienvenido a ZapTrack
          </CardTitle>
          <CardDescription className="text-lg text-blue-700">
            ¡Gracias por unirte a nosotros! Tu aventura comienza ahora.
          </CardDescription>
        </CardHeader>
        <CardContent className="space-y-6">
          <section className="bg-blue-50 p-4 rounded-lg">
            <h2 className="text-xl font-semibold text-blue-800 mb-2 flex items-center gap-2">
              <Rocket className="w-6 h-6" />
              Tu prueba gratuita
            </h2>
            <p className="text-blue-700">
              Has activado tu prueba gratuita de 1 GB de almacenamiento por un
              mes. Explora todas las funciones de ZapTrack y descubre cómo puede
              mejorar tu productividad.
            </p>
          </section>

          <section>
            <h2 className="text-xl font-semibold text-blue-800 mb-2 flex items-center gap-2">
              <Book className="w-6 h-6" />
              Primeros pasos
            </h2>
            <ul className="list-disc list-inside space-y-2 text-blue-700">
              <li>Completa tu perfil para personalizar tu experiencia</li>
              <li>
                Explora nuestro tutorial interactivo para conocer las funciones
                principales
              </li>
              <li>Conecta tus primeras aplicaciones y servicios</li>
              <li>Configura tus primeras automatizaciones</li>
            </ul>
          </section>

          <section className="bg-green-50 p-4 rounded-lg">
            <h2 className="text-xl font-semibold text-green-800 mb-2 flex items-center gap-2">
              <Settings className="w-6 h-6" />
              Configura tu cuenta
            </h2>
            <p className="text-green-700">
              Para aprovechar al máximo tu experiencia, te recomendamos
              configurar tus preferencias de notificación y seguridad en la
              sección de ajustes de tu cuenta.
            </p>
          </section>
        </CardContent>
        <CardFooter className="flex flex-col space-y-4">
          <Button
            onClick={() => handleNavigate("/dashboard")}
            className="w-full bg-gradient-to-r from-blue-600 to-green-400 hover:from-blue-700 hover:to-green-500 text-white"
          >
            Comenzar el tutorial
          </Button>
          <p className="text-sm text-center text-blue-700">
            ¿Necesitas ayuda? Visita nuestro{" "}
            <a href="/ayuda" className="text-blue-600 hover:underline">
              centro de ayuda
            </a>{" "}
            o contacta a{" "}
            <a href="/soporte" className="text-blue-600 hover:underline">
              soporte
            </a>
            .
          </p>
        </CardFooter>
      </Card>
    </div>
  );
}
