import { useState } from "react";
import { Button } from "@components/ui/button";
import { Input } from "@components/ui/input";
import Navbar from "@components/narbar/narbar";

import { Folder, File, MoreVertical, Plus } from "lucide-react";
import Footer from "@components/footer/footer";

export default function Dashboard() {
  const [files /* setFiles */] = useState([
    { id: 1, type: "folder", name: "Documentos", color: "text-blue-500" },
    { id: 2, type: "folder", name: "Imágenes", color: "text-green-500" },
    { id: 3, type: "file", name: "Informe.pdf", color: "text-red-500" },
    {
      id: 4,
      type: "file",
      name: "Presentación.pptx",
      color: "text-orange-500",
    },
    { id: 5, type: "folder", name: "Proyectos", color: "text-purple-500" },
    {
      id: 6,
      type: "file",
      name: "Hoja de cálculo.xlsx",
      color: "text-green-500",
    },
  ]);

  return (
    <div className="flex flex-col h-screen">
      <Navbar />

      <main className="flex-1 overflow-y-auto bg-gray-50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
          <div className="md:hidden mb-4">
            <Input
              type="search"
              placeholder="Buscar en ZapTrack"
              className="w-full"
            />
          </div>
          <div className="flex justify-between items-center mb-6">
            <h1 className="text-2xl font-semibold text-gray-900">
              Mis archivos
            </h1>
            <Button className="bg-gradient-to-r from-blue-600 to-green-400 hover:from-blue-700 hover:to-green-500">
              <Plus className="mr-2 h-4 w-4" /> Nuevo
            </Button>
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 sm:gap-6">
            {files.map((file) => (
              <div
                key={file.id}
                className="bg-white rounded-lg shadow-sm p-4 flex items-center space-x-4"
              >
                {file.type === "folder" ? (
                  <Folder className={`h-10 w-10 ${file.color}`} />
                ) : (
                  <File className={`h-10 w-10 ${file.color}`} />
                )}
                <div className="flex-1 min-w-0">
                  <p className="text-sm font-medium text-gray-900 truncate">
                    {file.name}
                  </p>
                </div>
                <Button variant="ghost" size="icon">
                  <MoreVertical className="h-5 w-5 text-gray-400" />
                </Button>
              </div>
            ))}
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
}
