import React, { useState } from "react";
import { Button } from "@components/ui/button";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@components/ui/select";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@components/ui/dialog";
import { Input } from "@components/ui/input";
import { ChevronLeft, ChevronRight, X } from "lucide-react";
import Navbar from "@components/narbar/narbar";

const daysOfWeek = ["Dom", "Lun", "Mar", "Mié", "Jue", "Vie", "Sáb"];
const months = [
  "Enero",
  "Febrero",
  "Marzo",
  "Abril",
  "Mayo",
  "Junio",
  "Julio",
  "Agosto",
  "Septiembre",
  "Octubre",
  "Noviembre",
  "Diciembre",
];

interface Event {
  id: string;
  title: string;
  date: Date;
}

export default function CalendarView() {
  const [currentDate, setCurrentDate] = useState(new Date());
  const [view, setView] = useState<"week" | "month">("month");
  const [events, setEvents] = useState<Event[]>([]);
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [newEventTitle, setNewEventTitle] = useState("");

  const goToToday = () => setCurrentDate(new Date());
  const goToPrevious = () => {
    const newDate = new Date(currentDate);
    if (view === "week") newDate.setDate(newDate.getDate() - 7);
    else newDate.setMonth(newDate.getMonth() - 1);
    setCurrentDate(newDate);
  };
  const goToNext = () => {
    const newDate = new Date(currentDate);
    if (view === "week") newDate.setDate(newDate.getDate() + 7);
    else newDate.setMonth(newDate.getMonth() + 1);
    setCurrentDate(newDate);
  };

  const getDaysInMonth = (date: Date) => {
    const year = date.getFullYear();
    const month = date.getMonth();
    const daysInMonth = new Date(year, month + 1, 0).getDate();
    const firstDayOfMonth = new Date(year, month, 1).getDay();
    const daysArray = [];

    for (let i = 0; i < firstDayOfMonth; i++) {
      daysArray.push(null);
    }

    for (let i = 1; i <= daysInMonth; i++) {
      daysArray.push(new Date(year, month, i));
    }

    return daysArray;
  };

  const addEvent = () => {
    if (selectedDate && newEventTitle.trim() !== "") {
      const newEvent: Event = {
        id: Date.now().toString(),
        title: newEventTitle,
        date: selectedDate,
      };
      setEvents([...events, newEvent]);
      setNewEventTitle("");
      setSelectedDate(null);
    }
  };

  const getEventsForDate = (date: Date) => {
    return events.filter(
      (event) =>
        event.date.getDate() === date.getDate() &&
        event.date.getMonth() === date.getMonth() &&
        event.date.getFullYear() === date.getFullYear()
    );
  };

  const renderMonthView = () => {
    const daysInMonth = getDaysInMonth(currentDate);
    return (
      <div className="grid grid-cols-7 gap-1">
        {daysOfWeek.map((day) => (
          <div key={day} className="text-center font-semibold text-sm py-2">
            {day}
          </div>
        ))}
        {daysInMonth.map((day, index) => (
          <div key={index} className="aspect-square border p-1">
            {day && (
              <Dialog>
                <DialogTrigger asChild>
                  <div
                    className={`h-full w-full flex flex-col cursor-pointer ${
                      day.getDate() === new Date().getDate() &&
                      day.getMonth() === new Date().getMonth() &&
                      day.getFullYear() === new Date().getFullYear()
                        ? "bg-blue-100 rounded-full"
                        : ""
                    }`}
                    onClick={() => setSelectedDate(day)}
                  >
                    <span className="text-sm">{day.getDate()}</span>
                    {getEventsForDate(day).map((event) => (
                      <div
                        key={event.id}
                        className="text-xs bg-blue-200 rounded mt-1 px-1 truncate"
                      >
                        {event.title}
                      </div>
                    ))}
                  </div>
                </DialogTrigger>
                <DialogContent>
                  <DialogHeader>
                    <DialogTitle>
                      Eventos para {day.getDate()} de {months[day.getMonth()]}{" "}
                      de {day.getFullYear()}
                    </DialogTitle>
                  </DialogHeader>
                  <div className="mt-4">
                    <h3 className="font-semibold mb-2">Eventos:</h3>
                    {getEventsForDate(day).length > 0 ? (
                      getEventsForDate(day).map((event) => (
                        <div
                          key={event.id}
                          className="flex justify-between items-center mb-2"
                        >
                          <span>{event.title}</span>
                          <Button
                            variant="ghost"
                            size="sm"
                            onClick={() =>
                              setEvents(events.filter((e) => e.id !== event.id))
                            }
                          >
                            <X className="h-4 w-4" />
                          </Button>
                        </div>
                      ))
                    ) : (
                      <p>No hay eventos para este día.</p>
                    )}
                  </div>
                  <div className="mt-4">
                    <h3 className="font-semibold mb-2">Añadir evento:</h3>
                    <div className="flex space-x-2">
                      <Input
                        value={newEventTitle}
                        onChange={(e) => setNewEventTitle(e.target.value)}
                        placeholder="Título del evento"
                      />
                      <Button onClick={addEvent}>Añadir</Button>
                    </div>
                  </div>
                </DialogContent>
              </Dialog>
            )}
          </div>
        ))}
      </div>
    );
  };

  const renderWeekView = () => {
    const startOfWeek = new Date(currentDate);
    startOfWeek.setDate(currentDate.getDate() - currentDate.getDay());
    const endOfWeek = new Date(startOfWeek);
    endOfWeek.setDate(startOfWeek.getDate() + 6);

    return (
      <div className="grid grid-cols-8 gap-1">
        <div></div>
        {daysOfWeek.map((day, index) => {
          const date = new Date(startOfWeek);
          date.setDate(startOfWeek.getDate() + index);
          return (
            <div key={day} className="text-center">
              <div className="font-semibold text-sm">{day}</div>
              <div className="text-xs">{date.getDate()}</div>
            </div>
          );
        })}
        {Array.from({ length: 24 }, (_, i) => i).map((hour) => (
          <React.Fragment key={hour}>
            <div className="text-right pr-2 text-xs">{`${hour
              .toString()
              .padStart(2, "0")}:00`}</div>
            {daysOfWeek.map((_, index) => {
              const date = new Date(startOfWeek);
              date.setDate(startOfWeek.getDate() + index);
              date.setHours(hour);
              return (
                <Dialog key={index}>
                  <DialogTrigger asChild>
                    <div
                      className="border-t border-l h-12 cursor-pointer"
                      onClick={() => setSelectedDate(date)}
                    >
                      {getEventsForDate(date).map((event) => (
                        <div
                          key={event.id}
                          className="text-xs bg-blue-200 rounded mt-1 px-1 truncate"
                        >
                          {event.title}
                        </div>
                      ))}
                    </div>
                  </DialogTrigger>
                  <DialogContent>
                    <DialogHeader>
                      <DialogTitle>
                        Eventos para {date.getDate()} de{" "}
                        {months[date.getMonth()]} de {date.getFullYear()} a las{" "}
                        {hour}:00
                      </DialogTitle>
                    </DialogHeader>
                    <div className="mt-4">
                      <h3 className="font-semibold mb-2">Eventos:</h3>
                      {getEventsForDate(date).length > 0 ? (
                        getEventsForDate(date).map((event) => (
                          <div
                            key={event.id}
                            className="flex justify-between items-center mb-2"
                          >
                            <span>{event.title}</span>
                            <Button
                              variant="ghost"
                              size="sm"
                              onClick={() =>
                                setEvents(
                                  events.filter((e) => e.id !== event.id)
                                )
                              }
                            >
                              <X className="h-4 w-4" />
                            </Button>
                          </div>
                        ))
                      ) : (
                        <p>No hay eventos para esta hora.</p>
                      )}
                    </div>
                    <div className="mt-4">
                      <h3 className="font-semibold mb-2">Añadir evento:</h3>
                      <div className="flex space-x-2">
                        <Input
                          value={newEventTitle}
                          onChange={(e) => setNewEventTitle(e.target.value)}
                          placeholder="Título del evento"
                        />
                        <Button onClick={addEvent}>Añadir</Button>
                      </div>
                    </div>
                  </DialogContent>
                </Dialog>
              );
            })}
          </React.Fragment>
        ))}
      </div>
    );
  };

  return (
    <div className="min-h-screen bg-white">
      <Navbar />
      <div className="max-w-full mx-auto px-4 sm:px-6 lg:px-8 py-4">
        <div className="flex flex-col sm:flex-row justify-between items-center mb-4">
          <h1 className="text-2xl font-bold text-gray-900 mb-2 sm:mb-0">
            Calendario ZapTrack
          </h1>
          <div className="flex space-x-2 items-center">
            <Button onClick={goToToday} variant="outline" size="sm">
              Hoy
            </Button>
            <Button onClick={goToPrevious} variant="outline" size="icon">
              <ChevronLeft className="h-4 w-4" />
            </Button>
            <Button onClick={goToNext} variant="outline" size="icon">
              <ChevronRight className="h-4 w-4" />
            </Button>
            <span className="font-semibold">
              {months[currentDate.getMonth()]} {currentDate.getFullYear()}
            </span>
            <Select
              value={view}
              onValueChange={(value: "week" | "month") => setView(value)}
            >
              <SelectTrigger className="w-[100px]">
                <SelectValue placeholder="Vista" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="week">Semana</SelectItem>
                <SelectItem value="month">Mes</SelectItem>
              </SelectContent>
            </Select>
          </div>
        </div>
        <div className="bg-white rounded-lg shadow-sm border overflow-x-auto">
          {view === "week" ? renderWeekView() : renderMonthView()}
        </div>
      </div>
    </div>
  );
}
