import { useCallServices } from "../../hooks";
import { useState } from "react";
import { register } from "@api/resources/users";
import { format } from "date-fns";

const Errors = {
  form: "Debes ingresar todos los datos",
  passwordsMismatch: "Las contraseñas no coinciden",
};

export const useRegister = () => {
  const { callEndpoint, loading } = useCallServices();

  // Estados para los nuevos campos del formulario
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [name, setName] = useState("");
  const [document_number, setDocument_number] = useState("");
  const [birthdate, setBirthdate] = useState("");
  const [gender, setGender] = useState("");
  const [usageType, setUsageType] = useState("");

  const initRegister = async () => {
    const formattedBirthdate = format(
      new Date(birthdate),
      "yyyy-MM-dd'T'HH:mm:ssXXX"
    );

    try {
      if (
        !email ||
        !password ||
        !name ||
        !document_number ||
        !birthdate ||
        !gender ||
        !usageType
      ) {
        throw new Error(Errors.form);
      }
      if (password !== confirmPassword) {
        throw new Error(Errors.passwordsMismatch);
      }

      const response = await callEndpoint(
        register({
          email,
          password,
          name,
          document_number,
          birthdate: formattedBirthdate,
          gender,
          Account_type: usageType,
          role: "Admin",
        })
      );

      return { success: true, response };
    } catch (e) {
      console.log(e);

      return { success: false };
    }
  };

  const handleEmail = (text: string) => setEmail(text);
  const handlePassword = (text: string) => setPassword(text);
  const handleConfirmPassword = (text: string) => setConfirmPassword(text);
  const handleName = (text: string) => setName(text);
  const handleDocument_number = (text: string) => setDocument_number(text);
  const handleBirthdate = (text: string) => setBirthdate(text);
  const handleGender = (text: string) => setGender(text);
  const handleUsageType = (text: string) => setUsageType(text);

  return {
    email,
    password,
    confirmPassword,
    name,
    document_number,
    birthdate,
    gender,
    usageType,
    handleEmail,
    handlePassword,
    handleConfirmPassword,
    handleName,
    handleDocument_number,
    handleBirthdate,
    handleGender,
    handleUsageType,
    initRegister,
    loading,
  };
};
