import {
  Upload,
  Share2,
  Shield,
  Users,
  Trello,
  MessageSquare,
  Calculator,
  Calendar,
} from "lucide-react";
import { motion } from "framer-motion";

const features = [
  {
    icon: <Upload className="h-6 w-6" />,
    title: "Subida rápida",
    description:
      "Sube tus archivos de forma rápida y sencilla con nuestra interfaz intuitiva.",
  },
  {
    icon: <Share2 className="h-6 w-6" />,
    title: "Compartir fácil",
    description: "Comparte tus archivos con quien quieras con solo un clic.",
  },
  {
    icon: <Shield className="h-6 w-6" />,
    title: "Seguridad avanzada",
    description:
      "Tus archivos están protegidos con encriptación de última generación.",
  },
  {
    icon: <Users className="h-6 w-6" />,
    title: "Gestión de personal",
    description: "Administra tu equipo y asigna tareas de manera eficiente.",
  },
  {
    icon: <Trello className="h-6 w-6" />,
    title: "Tablero de tareas",
    description:
      "Organiza y visualiza tus tareas con un tablero estilo Trello.",
  },
  {
    icon: <Trello className="h-6 w-6" />,
    title: "Notas",
    description: "Crea y organiza notas para mantener tus ideas en orden.",
  },
  {
    icon: <MessageSquare className="h-6 w-6" />,
    title: "Chat integrado",
    description: "Comunícate con tu equipo en tiempo real.",
  },
  {
    icon: <Calculator className="h-6 w-6" />,
    title: "Módulo contable",
    description: "Gestiona tus finanzas con nuestro módulo contable integrado.",
  },
  {
    icon: <Calendar className="h-6 w-6" />,
    title: "Calendario",
    description: "Planifica y organiza tus actividades con nuestro calendario.",
  },
];

export const FeaturesSection = () => {
  return (
    <div className="mt-24">
      <h2 className="text-center text-3xl font-extrabold text-gray-900">
        Características principales
      </h2>
      <div className="mt-12 grid gap-8 grid-cols-1 md:grid-cols-3">
        {features.map((feature, index) => (
          <motion.div
            key={index}
            className="flex flex-col items-center"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: index * 0.1 }}
          >
            <motion.div
              className="flex items-center justify-center h-12 w-12 rounded-md bg-gradient-to-r from-blue-600 to-green-400 text-white"
              whileHover={{ scale: 1.1, rotate: 360 }}
              transition={{
                type: "spring",
                stiffness: 260,
                damping: 20,
              }}
            >
              {feature.icon}
            </motion.div>
            <h3 className="mt-6 text-lg font-medium text-gray-900">
              {feature.title}
            </h3>
            <p className="mt-2 text-base text-gray-500 text-center">
              {feature.description}
            </p>
          </motion.div>
        ))}
      </div>
    </div>
  );
};
