/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpErrorHandler } from "@api/handlers/error";
import { AxiosResponse } from "axios";
import { useEffect, useState, useRef } from "react";

type TServiceResponse<T> = {
  response: Promise<AxiosResponse<T>>;
  controller: AbortController;
};

export const useCallServices = () => {
  const [loading, setLoading] = useState(false);
  const controllerRef = useRef<AbortController | null>(null);

  const callEndpoint = async <D>(axiosCall: TServiceResponse<D>) => {
    setLoading(true);
    controllerRef.current = axiosCall.controller;

    try {
      return (await axiosCall.response).data;
    } catch (err: any) {
      throw HttpErrorHandler(err);
    } finally {
      setLoading(false);
    }
  };

  const cancelEndpoint = () => {
    setLoading(false);
    if (controllerRef.current) {
      controllerRef.current.abort();
      controllerRef.current = null;
    }
  };

  useEffect(() => {
    return () => {
      cancelEndpoint();
    };
  }, []);

  return { loading, callEndpoint };
};
