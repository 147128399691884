import { useState, useRef, useEffect } from "react";
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from "@hello-pangea/dnd";
import { Button } from "@components/ui/button";
import { Input } from "@components/ui/input";
import { Card, CardContent } from "@components/ui/card";
import { Plus, MoreVertical, Pencil, X } from "lucide-react";
import Navbar from "@components/narbar/narbar";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@components/ui/dropdown-menu";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@components/ui/dialog";
import { Textarea } from "@components/ui/textarea";
import Footer from "@components/footer/footer";
import { Board, Column, Task, Tag } from "./types";
import { initialBoards } from "./use_board";

export default function BoardManager() {
  const [boards, setBoards] = useState<Board[]>(initialBoards);
  const [activeBoard, setActiveBoard] = useState<Board>(boards[0]);
  const [newTaskContent, setNewTaskContent] = useState<string>("");
  const [addingToColumn, setAddingToColumn] = useState<string | null>(null);
  const [editingColumnId, setEditingColumnId] = useState<string | null>(null);
  const [newColumnTitle, setNewColumnTitle] = useState<string>("");
  const [, /* selectedTask */ setSelectedTask] = useState<Task | null>(null);
  const editInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (editingColumnId && editInputRef.current) {
      editInputRef.current.focus();
    }
  }, [editingColumnId]);

  const onDragEnd = (result: DropResult) => {
    const { destination, source /* draggableId */ } = result;

    console.log("Drag result:", result);

    if (!destination) {
      console.log("Destination is null");
      return;
    }

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      console.log("Task is in the same position");
      return;
    }

    const startColumn = activeBoard.columns[source.droppableId];
    const finishColumn = activeBoard.columns[destination.droppableId];

    console.log("Start column:", startColumn);
    console.log("Finish column:", finishColumn);

    if (startColumn === finishColumn) {
      // Reordenar tareas en la misma columna
      const newTasks = Array.from(startColumn.tasks);
      const [movedTask] = newTasks.splice(source.index, 1);
      newTasks.splice(destination.index, 0, movedTask);

      console.log("Reordered tasks in the same column:", newTasks);

      const newColumn = {
        ...startColumn,
        tasks: newTasks,
      };

      setActiveBoard((prevBoard) => {
        console.log("Updating column:", newColumn);
        return {
          ...prevBoard,
          columns: {
            ...prevBoard.columns,
            [newColumn.id]: newColumn,
          },
        };
      });
    } else {
      // Mover tarea a una columna diferente
      const startTasks = Array.from(startColumn.tasks);
      const [movedTask] = startTasks.splice(source.index, 1);
      const newStartColumn = {
        ...startColumn,
        tasks: startTasks,
      };

      const finishTasks = Array.from(finishColumn.tasks);
      finishTasks.splice(destination.index, 0, movedTask);
      const newFinishColumn = {
        ...finishColumn,
        tasks: finishTasks,
      };

      console.log("Tasks after moving to a different column:", {
        startColumn: newStartColumn,
        finishColumn: newFinishColumn,
      });

      setActiveBoard((prevBoard) => {
        console.log("Updating board state:", {
          ...prevBoard,
          columns: {
            ...prevBoard.columns,
            [newStartColumn.id]: newStartColumn,
            [newFinishColumn.id]: newFinishColumn,
          },
        });
        return {
          ...prevBoard,
          columns: {
            ...prevBoard.columns,
            [newStartColumn.id]: newStartColumn,
            [newFinishColumn.id]: newFinishColumn,
          },
        };
      });
    }
  };

  const addNewTask = (columnId: string) => {
    if (newTaskContent.trim() === "") return;

    const newTask: Task = {
      id: `task-${Date.now()}`,
      content: newTaskContent,
      startDate: new Date().toISOString().split("T")[0],
      endDate: new Date().toISOString().split("T")[0],
      description: "",
      assignee: "",
      color: "#ffffff",
      tags: [],
    };

    setActiveBoard((prevBoard) => ({
      ...prevBoard,
      columns: {
        ...prevBoard.columns,
        [columnId]: {
          ...prevBoard.columns[columnId],
          tasks: [...prevBoard.columns[columnId].tasks, newTask],
        },
      },
    }));

    setNewTaskContent("");
    setAddingToColumn(null);
  };

  const addNewColumn = () => {
    const newColumnId = `column-${Date.now()}`;
    const newColumn: Column = {
      id: newColumnId,
      title: "Nueva columna",
      tasks: [],
    };

    setActiveBoard((prevBoard) => ({
      ...prevBoard,
      columns: {
        ...prevBoard.columns,
        [newColumnId]: newColumn,
      },
      columnOrder: [...prevBoard.columnOrder, newColumnId],
    }));
  };

  const startEditingColumn = (columnId: string) => {
    setEditingColumnId(columnId);
    setNewColumnTitle(activeBoard.columns[columnId].title);
  };

  const saveColumnTitle = () => {
    if (editingColumnId && newColumnTitle.trim() !== "") {
      setActiveBoard((prevBoard) => ({
        ...prevBoard,
        columns: {
          ...prevBoard.columns,
          [editingColumnId]: {
            ...prevBoard.columns[editingColumnId],
            title: newColumnTitle,
          },
        },
      }));
      setEditingColumnId(null);
    }
  };

  const deleteColumn = (columnId: string) => {
    setActiveBoard((prevBoard) => {
      const newColumns = { ...prevBoard.columns };
      delete newColumns[columnId];
      return {
        ...prevBoard,
        columns: newColumns,
        columnOrder: prevBoard.columnOrder.filter((id) => id !== columnId),
      };
    });
  };

  const deleteTask = (columnId: string, taskId: string) => {
    setActiveBoard((prevBoard) => ({
      ...prevBoard,
      columns: {
        ...prevBoard.columns,
        [columnId]: {
          ...prevBoard.columns[columnId],
          tasks: prevBoard.columns[columnId].tasks.filter(
            (task) => task.id !== taskId
          ),
        },
      },
    }));
  };

  const addNewBoard = () => {
    const newBoard: Board = {
      id: `board-${Date.now()}`,
      title: "Nuevo Tablero",
      type: "personal",
      columns: {},
      columnOrder: [],
      backgroundColor: "#f0f4f8",
      backgroundImage: "",
    };
    setBoards([...boards, newBoard]);
    setActiveBoard(newBoard);
  };

  const updateTask = (updatedTask: Task) => {
    setActiveBoard((prevBoard) => {
      const updatedColumns = { ...prevBoard.columns };
      for (const columnId in updatedColumns) {
        updatedColumns[columnId] = {
          ...updatedColumns[columnId],
          tasks: updatedColumns[columnId].tasks.map((task) =>
            task.id === updatedTask.id ? updatedTask : task
          ),
        };
      }
      return { ...prevBoard, columns: updatedColumns };
    });
    setSelectedTask(null);
  };

  const addTagToTask = (taskId: string, tag: Tag) => {
    setActiveBoard((prevBoard) => {
      const updatedColumns = { ...prevBoard.columns };
      for (const columnId in updatedColumns) {
        updatedColumns[columnId] = {
          ...updatedColumns[columnId],
          tasks: updatedColumns[columnId].tasks.map((task) =>
            task.id === taskId ? { ...task, tags: [...task.tags, tag] } : task
          ),
        };
      }
      return { ...prevBoard, columns: updatedColumns };
    });
  };

  const removeTagFromTask = (taskId: string, tagId: string) => {
    setActiveBoard((prevBoard) => {
      const updatedColumns = { ...prevBoard.columns };
      for (const columnId in updatedColumns) {
        updatedColumns[columnId] = {
          ...updatedColumns[columnId],
          tasks: updatedColumns[columnId].tasks.map((task) =>
            task.id === taskId
              ? { ...task, tags: task.tags.filter((tag) => tag.id !== tagId) }
              : task
          ),
        };
      }
      return { ...prevBoard, columns: updatedColumns };
    });
  };

  const updateBoardBackground = (
    backgroundColor: string,
    backgroundImage: string
  ) => {
    setActiveBoard((prevBoard) => ({
      ...prevBoard,
      backgroundColor,
      backgroundImage,
    }));
  };

  return (
    <div
      className="min-h-screen"
      style={{
        backgroundColor: activeBoard.backgroundColor,
        backgroundImage: `url(${activeBoard.backgroundImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <Navbar />
      <div className="max-w-full mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="flex flex-col sm:flex-row justify-between items-center mb-8">
          <h1 className="text-3xl font-bold text-gray-900 mb-4 sm:mb-0">
            {activeBoard.title}
          </h1>
          <div className="flex flex-col sm:flex-row space-y-2 sm:space-y-0 sm:space-x-4">
            <select
              value={activeBoard.id}
              onChange={(e) =>
                setActiveBoard(
                  boards.find((b) => b.id === e.target.value) || boards[0]
                )
              }
              className="border rounded p-2"
            >
              {boards.map((board) => (
                <option key={board.id} value={board.id}>
                  {board.title}
                </option>
              ))}
            </select>
            <Button onClick={addNewBoard}>Nuevo Tablero</Button>
            <Dialog>
              <DialogTrigger asChild>
                <Button>Cambiar fondo</Button>
              </DialogTrigger>
              <DialogContent>
                <DialogHeader>
                  <DialogTitle>Cambiar fondo del tablero</DialogTitle>
                </DialogHeader>
                <div className="grid gap-4 py-4">
                  <div className="grid grid-cols-4 items-center gap-4">
                    <label htmlFor="bg-color" className="text-right">
                      Color de fondo
                    </label>
                    <Input
                      id="bg-color"
                      type="color"
                      value={activeBoard.backgroundColor}
                      onChange={(e) =>
                        updateBoardBackground(
                          e.target.value,
                          activeBoard.backgroundImage
                        )
                      }
                      className="col-span-3"
                    />
                  </div>
                  <div className="grid grid-cols-4 items-center gap-4">
                    <label htmlFor="bg-image" className="text-right">
                      Imagen de fondo
                    </label>
                    <Input
                      id="bg-image"
                      type="text"
                      placeholder="URL de la imagen"
                      value={activeBoard.backgroundImage}
                      onChange={(e) =>
                        updateBoardBackground(
                          activeBoard.backgroundColor,
                          e.target.value
                        )
                      }
                      className="col-span-3"
                    />
                  </div>
                </div>
              </DialogContent>
            </Dialog>
          </div>
        </div>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable
            droppableId="all-columns"
            direction="horizontal"
            type="column"
          >
            {(provided) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                className="flex flex-col sm:flex-row space-y-6 sm:space-y-0 sm:space-x-6 overflow-x-auto pb-8"
              >
                {activeBoard.columnOrder.map((columnId, index) => {
                  const column = activeBoard.columns[columnId];
                  return (
                    <Draggable
                      key={column.id}
                      draggableId={column.id}
                      index={index}
                    >
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          className="w-full sm:w-80 flex-shrink-0"
                        >
                          <div className="bg-white p-4 rounded-lg shadow-md">
                            <div
                              {...provided.dragHandleProps}
                              className="flex justify-between items-center mb-4"
                            >
                              {editingColumnId === column.id ? (
                                <Input
                                  ref={editInputRef}
                                  type="text"
                                  value={newColumnTitle}
                                  onChange={(e) =>
                                    setNewColumnTitle(e.target.value)
                                  }
                                  onBlur={saveColumnTitle}
                                  onKeyPress={(e) => {
                                    if (e.key === "Enter") {
                                      saveColumnTitle();
                                    }
                                  }}
                                  className="font-semibold text-lg"
                                />
                              ) : (
                                <h2 className="font-semibold text-lg text-gray-800">
                                  {column.title}
                                </h2>
                              )}
                              <DropdownMenu>
                                <DropdownMenuTrigger asChild>
                                  <Button variant="ghost" size="sm">
                                    <MoreVertical className="h-4 w-4" />
                                  </Button>
                                </DropdownMenuTrigger>
                                <DropdownMenuContent>
                                  <DropdownMenuItem
                                    onSelect={() =>
                                      startEditingColumn(column.id)
                                    }
                                  >
                                    <Pencil className="mr-2 h-4 w-4" />
                                    Editar título
                                  </DropdownMenuItem>
                                  <DropdownMenuItem
                                    onSelect={() => deleteColumn(column.id)}
                                  >
                                    <X className="mr-2 h-4 w-4" />
                                    Eliminar columna
                                  </DropdownMenuItem>
                                </DropdownMenuContent>
                              </DropdownMenu>
                            </div>
                            <Droppable droppableId={column.id} type="task">
                              {(provided) => {
                                console.log(
                                  "Droppable props:",
                                  provided.droppableProps
                                );
                                return (
                                  <div
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                    className="min-h-[200px]"
                                  >
                                    {column.tasks.map((task, index) => (
                                      <Draggable
                                        key={task.id}
                                        draggableId={task.id}
                                        index={index}
                                      >
                                        {(provided) => {
                                          console.log(
                                            "Draggable props:",
                                            provided.draggableProps
                                          );
                                          console.log(
                                            "Draggable handle props:",
                                            provided.dragHandleProps
                                          );
                                          return (
                                            <Card
                                              ref={provided.innerRef}
                                              {...provided.draggableProps}
                                              {...provided.dragHandleProps}
                                              className="mb-3 border border-gray-200 hover:shadow-md transition-shadow duration-200"
                                              style={{
                                                backgroundColor: task.color,
                                              }}
                                            >
                                              <CardContent className="p-3">
                                                <div className="flex justify-between items-start">
                                                  <Dialog>
                                                    <DialogTrigger asChild>
                                                      <Button
                                                        variant="ghost"
                                                        className="w-full justify-start p-0 h-auto"
                                                      >
                                                        <div>
                                                          <p className="text-sm text-gray-700 font-medium mb-1 text-left">
                                                            {task.content}
                                                          </p>
                                                          <div className="flex flex-wrap gap-1">
                                                            {task.tags.map(
                                                              (tag) => (
                                                                <span
                                                                  key={tag.id}
                                                                  className="inline-block px-2 py-1 text-xs font-semibold rounded-full"
                                                                  style={{
                                                                    backgroundColor:
                                                                      tag.color,
                                                                    color:
                                                                      "#ffffff",
                                                                  }}
                                                                >
                                                                  {tag.name}
                                                                </span>
                                                              )
                                                            )}
                                                          </div>
                                                        </div>
                                                      </Button>
                                                    </DialogTrigger>
                                                    <DialogContent>
                                                      <DialogHeader>
                                                        <DialogTitle>
                                                          {task.content}
                                                        </DialogTitle>
                                                      </DialogHeader>
                                                      <div className="grid gap-4 py-4">
                                                        <div className="grid grid-cols-4 items-center gap-4">
                                                          <label
                                                            htmlFor="start-date"
                                                            className="text-right"
                                                          >
                                                            Fecha de inicio
                                                          </label>
                                                          <Input
                                                            id="start-date"
                                                            type="date"
                                                            value={
                                                              task.startDate
                                                            }
                                                            onChange={(e) =>
                                                              updateTask({
                                                                ...task,
                                                                startDate:
                                                                  e.target
                                                                    .value,
                                                              })
                                                            }
                                                            className="col-span-3"
                                                          />
                                                        </div>
                                                        <div className="grid grid-cols-4 items-center gap-4">
                                                          <label
                                                            htmlFor="end-date"
                                                            className="text-right"
                                                          >
                                                            Fecha de fin
                                                          </label>
                                                          <Input
                                                            id="end-date"
                                                            type="date"
                                                            value={task.endDate}
                                                            onChange={(e) =>
                                                              updateTask({
                                                                ...task,
                                                                endDate:
                                                                  e.target
                                                                    .value,
                                                              })
                                                            }
                                                            className="col-span-3"
                                                          />
                                                        </div>
                                                        <div className="grid grid-cols-4 items-center gap-4">
                                                          <label
                                                            htmlFor="description"
                                                            className="text-right"
                                                          >
                                                            Descripción
                                                          </label>
                                                          <Textarea
                                                            id="description"
                                                            value={
                                                              task.description
                                                            }
                                                            onChange={(e) =>
                                                              updateTask({
                                                                ...task,
                                                                description:
                                                                  e.target
                                                                    .value,
                                                              })
                                                            }
                                                            className="col-span-3"
                                                          />
                                                        </div>
                                                        <div className="grid grid-cols-4 items-center gap-4">
                                                          <label
                                                            htmlFor="assignee"
                                                            className="text-right"
                                                          >
                                                            Asignado a
                                                          </label>
                                                          <Input
                                                            id="assignee"
                                                            value={
                                                              task.assignee
                                                            }
                                                            onChange={(e) =>
                                                              updateTask({
                                                                ...task,
                                                                assignee:
                                                                  e.target
                                                                    .value,
                                                              })
                                                            }
                                                            className="col-span-3"
                                                          />
                                                        </div>
                                                        <div className="grid grid-cols-4 items-center gap-4">
                                                          <label
                                                            htmlFor="task-color"
                                                            className="text-right"
                                                          >
                                                            Color de la tarea
                                                          </label>
                                                          <Input
                                                            id="task-color"
                                                            type="color"
                                                            value={task.color}
                                                            onChange={(e) =>
                                                              updateTask({
                                                                ...task,
                                                                color:
                                                                  e.target
                                                                    .value,
                                                              })
                                                            }
                                                            className="col-span-3"
                                                          />
                                                        </div>
                                                        <div className="grid grid-cols-4 items-center gap-4">
                                                          <label
                                                            htmlFor="new-tag"
                                                            className="text-right"
                                                          >
                                                            Nueva etiqueta
                                                          </label>
                                                          <Input
                                                            id="new-tag"
                                                            placeholder="Nombre de la etiqueta"
                                                            className="col-span-2"
                                                          />
                                                          <Button
                                                            onClick={() => {
                                                              const tagName = (
                                                                document.getElementById(
                                                                  "new-tag"
                                                                ) as HTMLInputElement
                                                              ).value;
                                                              if (tagName) {
                                                                addTagToTask(
                                                                  task.id,
                                                                  {
                                                                    id: `tag-${Date.now()}`,
                                                                    name: tagName,
                                                                    color:
                                                                      "#" +
                                                                      Math.floor(
                                                                        Math.random() *
                                                                          16777215
                                                                      ).toString(
                                                                        16
                                                                      ),
                                                                  }
                                                                );
                                                                (
                                                                  document.getElementById(
                                                                    "new-tag"
                                                                  ) as HTMLInputElement
                                                                ).value = "";
                                                              }
                                                            }}
                                                          >
                                                            Añadir
                                                          </Button>
                                                        </div>
                                                        <div className="flex flex-wrap gap-2">
                                                          {task.tags.map(
                                                            (tag) => (
                                                              <div
                                                                key={tag.id}
                                                                className="flex items-center gap-1 px-2 py-1 rounded-full text-sm"
                                                                style={{
                                                                  backgroundColor:
                                                                    tag.color,
                                                                }}
                                                              >
                                                                {tag.name}
                                                                <Button
                                                                  variant="ghost"
                                                                  size="sm"
                                                                  onClick={() =>
                                                                    removeTagFromTask(
                                                                      task.id,
                                                                      tag.id
                                                                    )
                                                                  }
                                                                >
                                                                  <X className="h-3 w-3" />
                                                                </Button>
                                                              </div>
                                                            )
                                                          )}
                                                        </div>
                                                      </div>
                                                    </DialogContent>
                                                  </Dialog>
                                                  <DropdownMenu>
                                                    <DropdownMenuTrigger
                                                      asChild
                                                    >
                                                      <Button
                                                        variant="ghost"
                                                        size="sm"
                                                      >
                                                        <MoreVertical className="h-4 w-4" />
                                                      </Button>
                                                    </DropdownMenuTrigger>
                                                    <DropdownMenuContent>
                                                      <DropdownMenuItem
                                                        onSelect={() =>
                                                          deleteTask(
                                                            column.id,
                                                            task.id
                                                          )
                                                        }
                                                      >
                                                        <X className="mr-2 h-4 w-4" />
                                                        Eliminar tarea
                                                      </DropdownMenuItem>
                                                    </DropdownMenuContent>
                                                  </DropdownMenu>
                                                </div>
                                              </CardContent>
                                            </Card>
                                          );
                                        }}
                                      </Draggable>
                                    ))}
                                    {provided.placeholder}
                                  </div>
                                );
                              }}
                            </Droppable>
                            {addingToColumn === column.id ? (
                              <div className="mt-3">
                                <Input
                                  type="text"
                                  placeholder="Ingrese el contenido de la tarea"
                                  value={newTaskContent}
                                  onChange={(e) =>
                                    setNewTaskContent(e.target.value)
                                  }
                                  className="mb-2"
                                />
                                <div className="flex space-x-2">
                                  <Button
                                    onClick={() => addNewTask(column.id)}
                                    className="bg-gradient-to-r from-blue-600 to-green-400 hover:from-blue-700 hover:to-green-500 text-white"
                                  >
                                    Añadir
                                  </Button>
                                  <Button
                                    onClick={() => setAddingToColumn(null)}
                                    variant="outline"
                                  >
                                    Cancelar
                                  </Button>
                                </div>
                              </div>
                            ) : (
                              <Button
                                onClick={() => setAddingToColumn(column.id)}
                                className="mt-3 w-full bg-gradient-to-r from-blue-600 to-green-400 hover:from-blue-700 hover:to-green-500 text-white"
                              >
                                <Plus className="mr-2 h-4 w-4" /> Añadir tarea
                              </Button>
                            )}
                          </div>
                        </div>
                      )}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
                <div className="w-full sm:w-80 flex-shrink-0">
                  <Button
                    onClick={addNewColumn}
                    className="w-full h-full min-h-[200px] bg-white hover:bg-gray-50 text-gray-700 border border-dashed border-gray-300 rounded-lg shadow-sm"
                  >
                    <Plus className="mr-2 h-6 w-6" /> Añadir columna
                  </Button>
                </div>
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
      <Footer />
    </div>
  );
}
