import  { useState, useEffect } from 'react';
import { Button } from "@components/ui/button";
import { Input } from "@components/ui/input";
import { ScrollArea } from "@components/ui/scroll-area";
import { Textarea } from "@components/ui/textarea";
import { Plus, Search, Trash2, FolderPlus, ChevronDown } from "lucide-react";
import Navbar from "@components/narbar/narbar";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@components/ui/dropdown-menu";

interface Note {
  id: string;
  title: string;
  content: string;
  lastModified: Date;
}

interface Folder {
  id: string;
  name: string;
}

export default function NotesView() {
  const [notes, setNotes] = useState<Note[]>([
    { id: '1', title: 'Bienvenido a ZapTrack Notes', content: 'Empieza a escribir tus notas aquí...', lastModified: new Date() },
    { id: '2', title: 'Ideas para el proyecto', content: '1. Implementar autenticación\n2. Mejorar el diseño\n3. Añadir funcionalidad de búsqueda', lastModified: new Date(Date.now() - 86400000) },
  ]);
  const [folders, setFolders] = useState<Folder[]>([
    { id: '1', name: 'Todas las notas' },
    { id: '2', name: 'Trabajo' },
    { id: '3', name: 'Personal' },
  ]);
  const [selectedNote, setSelectedNote] = useState<Note | null>(null);
  const [selectedFolder, setSelectedFolder] = useState<Folder>(folders[0]);
  const [searchTerm, setSearchTerm] = useState('');
  const [isMobileView, setIsMobileView] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth < 768);
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const filteredNotes = notes.filter(note =>
    note.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
    note.content.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleCreateNote = () => {
    const newNote: Note = {
      id: Date.now().toString(),
      title: 'Nueva nota',
      content: '',
      lastModified: new Date(),
    };
    setNotes([newNote, ...notes]);
    setSelectedNote(newNote);
  };

  const handleUpdateNote = (updatedNote: Note) => {
    setNotes(notes.map(note => note.id === updatedNote.id ? { ...updatedNote, lastModified: new Date() } : note));
    setSelectedNote(updatedNote);
  };

  const handleDeleteNote = (noteId: string) => {
    setNotes(notes.filter(note => note.id !== noteId));
    setSelectedNote(null);
  };

  const handleCreateFolder = () => {
    const folderName = prompt('Nombre de la nueva carpeta:');
    if (folderName) {
      const newFolder: Folder = {
        id: Date.now().toString(),
        name: folderName,
      };
      setFolders([...folders, newFolder]);
    }
  };

  return (
    <div className="min-h-screen bg-gray-100">
      <Navbar />
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="bg-white rounded-lg shadow-lg overflow-hidden h-[calc(100vh-12rem)]">
          <div className="flex h-full">
            {/* Sidebar */}
            {(!isMobileView || !selectedNote) && (
              <div className="w-full md:w-64 bg-gray-50 border-r">
                <div className="p-4">
                  <Button onClick={handleCreateNote} className="w-full mb-4">
                    <Plus className="mr-2 h-4 w-4" /> Nueva Nota
                  </Button>
                  <div className="relative">
                    <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
                    <Input
                      type="search"
                      placeholder="Buscar notas"
                      className="pl-10"
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                    />
                  </div>
                </div>
                <ScrollArea className="h-[calc(100vh-20rem)]">
                  <div className="p-2">
                    {folders.map(folder => (
                      <Button
                        key={folder.id}
                        variant="ghost"
                        className={`w-full justify-start mb-1 ${selectedFolder.id === folder.id ? 'bg-blue-100' : ''}`}
                        onClick={() => setSelectedFolder(folder)}
                      >
                        {folder.name}
                      </Button>
                    ))}
                    <Button variant="ghost" className="w-full justify-start text-blue-500" onClick={handleCreateFolder}>
                      <FolderPlus className="mr-2 h-4 w-4" /> Nueva Carpeta
                    </Button>
                  </div>
                  <div className="p-2">
                    {filteredNotes.map(note => (
                      <div
                        key={note.id}
                        className={`p-2 cursor-pointer rounded ${selectedNote?.id === note.id ? 'bg-blue-100' : 'hover:bg-gray-100'}`}
                        onClick={() => setSelectedNote(note)}
                      >
                        <h3 className="font-semibold truncate">{note.title}</h3>
                        <p className="text-sm text-gray-500 truncate">{note.content}</p>
                        <p className="text-xs text-gray-400 mt-1">
                          {note.lastModified.toLocaleDateString()} {note.lastModified.toLocaleTimeString()}
                        </p>
                      </div>
                    ))}
                  </div>
                </ScrollArea>
              </div>
            )}

            {/* Note Editor */}
            {(!isMobileView || selectedNote) && (
              <div className="flex-1 flex flex-col">
                {selectedNote ? (
                  <>
                    <div className="p-4 border-b flex justify-between items-center">
                      <Input
                        value={selectedNote.title}
                        onChange={(e) => handleUpdateNote({ ...selectedNote, title: e.target.value })}
                        className="text-xl font-semibold border-none"
                        placeholder="Título de la nota"
                      />
                      <DropdownMenu>
                        <DropdownMenuTrigger asChild>
                          <Button variant="ghost" size="icon">
                            <ChevronDown className="h-4 w-4" />
                          </Button>
                        </DropdownMenuTrigger>
                        <DropdownMenuContent align="end">
                          <DropdownMenuItem onClick={() => handleDeleteNote(selectedNote.id)}>
                            <Trash2 className="mr-2 h-4 w-4" />
                            <span>Eliminar nota</span>
                          </DropdownMenuItem>
                        </DropdownMenuContent>
                      </DropdownMenu>
                    </div>
                    <ScrollArea className="flex-1 p-4">
                      <Textarea
                        value={selectedNote.content}
                        onChange={(e) => handleUpdateNote({ ...selectedNote, content: e.target.value })}
                        className="w-full h-full min-h-[300px] border-none resize-none"
                        placeholder="Escribe tu nota aquí..."
                      />
                    </ScrollArea>
                  </>
                ) : (
                  <div className="flex-1 flex items-center justify-center text-gray-500">
                    Selecciona una nota o crea una nueva para empezar
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}