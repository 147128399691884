import { Button } from "@components/ui/button";
import { Input } from "@components/ui/input";
import {
  Zap,
  Search,
  Grid,
  Calendar,
  MessageSquare,
  Folder,
  Users,
  DollarSign,
} from "lucide-react";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@components/ui/dropdown-menu";
import { useNavigate } from "react-router-dom";
import DropdownMenuUser from "./DropdownMenu";
import { NavbarMovile } from "./narbarMovile";

export default function Navbar() {
  const navigate = useNavigate();

  const handleNavigate = (nav: string) => {
    navigate(nav);
  };

  return (
    <header className="bg-white shadow-sm">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between items-center py-4">
          <div className="flex items-center">
            <Zap className="h-8 w-auto sm:h-10 text-yellow-400" />
            <a
              className="cursor-pointer"
              onClick={() => handleNavigate("/dashboard")}
            >
              <span className="ml-2 text-xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-blue-600 to-green-400">
                ZapTrack
              </span>
            </a>
          </div>
          <div className="hidden md:flex flex-1 max-w-xl mx-4">
            <div className="relative w-full">
              <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
              <Input
                type="search"
                placeholder="Buscar en ZapTrack"
                className="pl-10 pr-4 py-2 w-full"
              />
            </div>
          </div>
          <nav className="hidden md:flex items-center space-x-1">
            <Button
              variant="ghost"
              size="sm"
              onClick={() => handleNavigate("/dashboard")}
            >
              <Folder className="h-5 w-5 mr-2" />
              Archivos
            </Button>
            <Button
              variant="ghost"
              size="sm"
              onClick={() => handleNavigate("/board")}
            >
              <Grid className="h-5 w-5 mr-2" />
              Tablero
            </Button>
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button variant="ghost" size="sm">
                  <Users className="h-5 w-5 mr-2" />
                  RRHH
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent>
                <DropdownMenuItem
                  onSelect={() => handleNavigate("/rrhh/personal")}
                >
                  Manejo de Personal
                </DropdownMenuItem>
                <DropdownMenuItem
                  onSelect={() => handleNavigate("/rrhh/reclutamiento")}
                >
                  Reclutamiento
                </DropdownMenuItem>
                <DropdownMenuItem
                  onSelect={() => handleNavigate("/rrhh/capacitacion")}
                >
                  Capacitación
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button variant="ghost" size="sm">
                  <DollarSign className="h-5 w-5 mr-2" />
                  Contabilidad
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent>
                <DropdownMenuItem
                  onSelect={() => handleNavigate("/contabilidad/facturacion")}
                >
                  Facturación
                </DropdownMenuItem>
                <DropdownMenuItem
                  onSelect={() => handleNavigate("/contabilidad/impuestos")}
                >
                  Impuestos
                </DropdownMenuItem>
                <DropdownMenuItem
                  onSelect={() => handleNavigate("/contabilidad/informes")}
                >
                  Informes Financieros
                </DropdownMenuItem>
                <DropdownMenuItem
                  onSelect={() => handleNavigate("/contabilidad/inventario")}
                >
                  Inventario
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
            <Button
              variant="ghost"
              size="sm"
              onClick={() => handleNavigate("/calendar")}
            >
              <Calendar className="h-5 w-5 mr-2" />
              Calendario
            </Button>
            <Button
              variant="ghost"
              size="sm"
              onClick={() => handleNavigate("/chat")}
            >
              <MessageSquare className="h-5 w-5 mr-2" />
              Chat
            </Button>
            <DropdownMenuUser />
          </nav>
          <NavbarMovile />
        </div>
      </div>
    </header>
  );
}
