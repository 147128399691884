import { ScrollArea } from "@components/ui/scroll-area";
import { Card, CardContent, CardHeader, CardTitle } from "@components/ui/card";

export default function Privacy() {
  return (
    <div className="container mx-auto py-8 px-4">
      <Card className="w-full max-w-4xl mx-auto">
        <CardHeader>
          <CardTitle className="text-2xl md:text-3xl font-bold text-center text-blue-900">
            Políticas de Privacidad de ZapTrack
          </CardTitle>
        </CardHeader>
        <CardContent>
          <ScrollArea className="h-[60vh] md:h-[70vh] overflow-auto pr-4">
            <div className="space-y-6 text-sm md:text-base">
              <section>
                <h2 className="text-xl font-semibold mb-2">1. Introducción</h2>
                <p>
                  ZapTrack está comprometido con la protección de su privacidad
                  y el cumplimiento de la Ley 1581 de 2012 de Protección de
                  Datos Personales de Colombia. Esta Política de Privacidad
                  describe cómo recopilamos, usamos y protegemos su información
                  personal.
                </p>
              </section>

              <section>
                <h2 className="text-xl font-semibold mb-2">
                  2. Información que Recopilamos
                </h2>
                <p>Recopilamos la siguiente información personal:</p>
                <ul className="list-disc list-inside pl-4">
                  <li>Nombre completo</li>
                  <li>Documento de identidad</li>
                  <li>Fecha de nacimiento</li>
                  <li>Género</li>
                  <li>Correo electrónico</li>
                  <li>Información de uso del servicio</li>
                </ul>
              </section>

              <section>
                <h2 className="text-xl font-semibold mb-2">
                  3. Cómo Utilizamos su Información
                </h2>
                <p>Utilizamos su información personal para:</p>
                <ul className="list-disc list-inside pl-4">
                  <li>Proporcionar y mejorar nuestros servicios</li>
                  <li>
                    Comunicarnos con usted sobre su cuenta y actualizaciones del
                    servicio
                  </li>
                  <li>Personalizar su experiencia de usuario</li>
                  <li>Cumplir con obligaciones legales y regulatorias</li>
                </ul>
              </section>

              <section>
                <h2 className="text-xl font-semibold mb-2">
                  4. Protección de Datos
                </h2>
                <p>
                  Implementamos medidas de seguridad técnicas, administrativas y
                  físicas para proteger sus datos personales contra acceso no
                  autorizado, alteración, divulgación o destrucción.
                </p>
              </section>

              <section>
                <h2 className="text-xl font-semibold mb-2">
                  5. Compartir Información
                </h2>
                <p>
                  No vendemos, intercambiamos ni transferimos su información
                  personal a terceros sin su consentimiento, excepto cuando sea
                  necesario para proporcionar nuestros servicios o cumplir con
                  requisitos legales.
                </p>
              </section>

              <section>
                <h2 className="text-xl font-semibold mb-2">6. Sus Derechos</h2>
                <p>
                  De acuerdo con la Ley 1581 de 2012, usted tiene derecho a:
                </p>
                <ul className="list-disc list-inside pl-4">
                  <li>Conocer, actualizar y rectificar sus datos personales</li>
                  <li>
                    Solicitar prueba de la autorización otorgada para el
                    tratamiento de sus datos
                  </li>
                  <li>
                    Ser informado sobre el uso que se ha dado a sus datos
                    personales
                  </li>
                  <li>
                    Presentar quejas ante la Superintendencia de Industria y
                    Comercio por infracciones a la ley
                  </li>
                  <li>
                    Revocar la autorización y/o solicitar la supresión de sus
                    datos
                  </li>
                  <li>
                    Acceder gratuitamente a sus datos personales que hayan sido
                    objeto de tratamiento
                  </li>
                </ul>
              </section>

              <section>
                <h2 className="text-xl font-semibold mb-2">
                  7. Cookies y Tecnologías Similares
                </h2>
                <p>
                  Utilizamos cookies y tecnologías similares para mejorar su
                  experiencia de navegación, analizar el uso del sitio y
                  personalizar el contenido. Puede configurar su navegador para
                  rechazar todas las cookies o para indicar cuándo se envía una
                  cookie.
                </p>
              </section>

              <section>
                <h2 className="text-xl font-semibold mb-2">
                  8. Cambios a esta Política
                </h2>
                <p>
                  Nos reservamos el derecho de modificar esta política de
                  privacidad en cualquier momento. Cualquier cambio será
                  efectivo inmediatamente después de la publicación de la
                  política revisada en nuestro sitio web.
                </p>
              </section>

              <section>
                <h2 className="text-xl font-semibold mb-2">9. Contacto</h2>
                <p>
                  Si tiene preguntas sobre esta Política de Privacidad o desea
                  ejercer sus derechos, puede contactarnos a través de:
                </p>
                <ul className="list-disc list-inside pl-4">
                  <li>Correo electrónico: privacidad@zaptrack.com</li>
                  <li>Dirección: [Dirección física de ZapTrack]</li>
                  <li>Teléfono: [Número de teléfono de atención al cliente]</li>
                </ul>
              </section>

              <section>
                <h2 className="text-xl font-semibold mb-2">
                  10. Consentimiento
                </h2>
                <p>
                  Al utilizar nuestros servicios, usted consiente la
                  recopilación y uso de su información como se describe en esta
                  Política de Privacidad.
                </p>
              </section>
            </div>
          </ScrollArea>
        </CardContent>
      </Card>
    </div>
  );
}
