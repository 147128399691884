import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useLogin } from "./use_login";
import { Button } from "@components/ui/button";
import { Input } from "@components/ui/input";
import { Label } from "@components/ui/label";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@components/ui/card";
import {
  Zap,
  LockIcon,
  MailIcon,
  EyeIcon,
  EyeOffIcon,
  ArrowLeft,
} from "lucide-react";
import ErrorNotification from "@components/errors/ErrorNotification";
import { motion, AnimatePresence } from "framer-motion";

export default function Login() {
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const { email, password, handleEmail, handlePassword, initLogin, loading } =
    useLogin();
  const navigate = useNavigate();
  const [showAnimation, setShowAnimation] = useState(false);

  useEffect(() => {
    const userString = localStorage.getItem("user");
    const userData = JSON.parse(userString || "{}");

    if (userData.item && userData.item.token) {
      navigate("/dashboard", { replace: true });
    }
  }, [navigate]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError(null);

    const { success, error: errorMessage } = await initLogin();
    if (success) {
      setShowAnimation(true);
      setTimeout(() => {
        setShowAnimation(false);
        navigate("/dashboard", { replace: true });
      }, 2000);
    } else {
      setError(errorMessage!);
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gradient-to-br from-blue-400 to-blue-600">
      <AnimatePresence>
        {showAnimation && (
          <motion.div
            initial={{ opacity: 0, scale: 0.5 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.5 }}
            transition={{ duration: 0.5 }}
            className="fixed inset-0 flex items-center justify-center bg-white z-50"
          >
            <div className="flex flex-col items-center">
              <Zap
                className="w-20 h-20 text-yellow-400 stroke-current"
                strokeWidth={2}
              />
              <motion.div
                initial={{ width: 0 }}
                animate={{ width: "100%" }}
                transition={{ duration: 1.5, delay: 0.5 }}
                className="h-2 bg-gradient-to-r from-blue-600 to-green-400 mt-4"
                style={{ width: "200px" }}
              />
            </div>
          </motion.div>
        )}
      </AnimatePresence>

      <Card className="w-full max-w-md bg-white/90 backdrop-blur-sm">
        <CardHeader className="space-y-1">
          <div className="relative">
            <Button
              variant="ghost"
              size="icon"
              onClick={() => navigate("/")}
              className="absolute left-0 top-0 text-blue-600 hover:text-blue-800"
            >
              <ArrowLeft className="h-6 w-6" />
              <span className="sr-only">Volver al inicio</span>
            </Button>
            <CardTitle className="text-3xl font-bold text-center text-blue-900 pt-2">
              <div className="flex items-center justify-center space-x-2">
                <Zap
                  className="w-10 h-10 text-yellow-400 stroke-current"
                  strokeWidth={3}
                />
                <span className="bg-clip-text text-transparent bg-gradient-to-r from-blue-600 to-green-400">
                  ZapTrack
                </span>
              </div>
            </CardTitle>
          </div>
          <CardDescription className="text-center text-blue-700">
            Inicia sesión para acceder a tu cuenta
          </CardDescription>
        </CardHeader>
        <CardContent className="space-y-4">
          {error && (
            <ErrorNotification
              message={error}
              title="Error de Autenticación"
              duration={10000}
              onClose={() => setError(null)}
            />
          )}
          <form onSubmit={handleSubmit}>
            <div className="space-y-2">
              <Label htmlFor="email" className="sr-only">
                Correo electrónico
              </Label>
              <div className="relative">
                <MailIcon className="absolute left-3 top-1/2 transform -translate-y-1/2 text-blue-500" />
                <Input
                  id="email"
                  placeholder="Correo electrónico"
                  type="email"
                  value={email}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    handleEmail(e.target.value)
                  }
                  className="pl-10 border-blue-300 focus:border-green-400 focus:ring-green-400"
                  required
                />
              </div>
            </div>
            <div className="space-y-2">
              <Label htmlFor="password" className="sr-only">
                Contraseña
              </Label>
              <div className="relative">
                <LockIcon className="absolute left-3 top-1/2 transform -translate-y-1/2 text-blue-500" />
                <Input
                  id="password"
                  placeholder="Contraseña"
                  value={password}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    handlePassword(e.target.value)
                  }
                  type={showPassword ? "text" : "password"}
                  className="pl-10 pr-10 border-blue-300 focus:border-green-400 focus:ring-green-400"
                  required
                />
                <Button
                  type="button"
                  variant="ghost"
                  size="icon"
                  className="absolute right-0 top-0 h-full px-3 text-blue-500 hover:text-blue-600"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? (
                    <EyeOffIcon className="h-4 w-4" />
                  ) : (
                    <EyeIcon className="h-4 w-4" />
                  )}
                  <span className="sr-only">
                    {showPassword ? "Ocultar contraseña" : "Mostrar contraseña"}
                  </span>
                </Button>
              </div>
            </div>
            <Button
              type="submit"
              className="w-full mt-4 bg-gradient-to-r from-blue-600 to-green-400 hover:from-blue-700 hover:to-green-500 text-white"
              disabled={loading || showAnimation}
            >
              {loading ? "Iniciando sesión..." : "Iniciar sesión"}
            </Button>
          </form>
        </CardContent>
        <CardFooter className="flex flex-col space-y-2">
          <div className="text-center text-sm text-blue-700">
            ¿No tienes una cuenta?{" "}
            <a href="/register" className="text-green-500 hover:underline">
              Regístrate
            </a>
          </div>
        </CardFooter>
      </Card>
    </div>
  );
}
