import React, { useEffect, useState } from "react";
import { Navigate, useLocation } from "react-router-dom";

const ProtectedRoute = ({ children }: { children: React.ReactNode }) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean | null>(null);
  const location = useLocation();

  useEffect(() => {
    const checkAuth = () => {
      const userString = localStorage.getItem("user");

      let userData;
      try {
        userData = JSON.parse(userString || "{}");
      } catch (error) {
        console.error("Error parsing user data from localStorage:", error);
        userData = {};
      }

      const token = userData?.token || userData?.item?.token;

      const hasToken = !!token;

      setIsAuthenticated(hasToken);
    };

    checkAuth();
    window.addEventListener("storage", checkAuth);

    return () => {
      window.removeEventListener("storage", checkAuth);
    };
  }, []);

  if (isAuthenticated === null) {
    return null;
  }

  if (!isAuthenticated) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return <>{children}</>;
};

export default ProtectedRoute;
