import { Users } from "../types/register";
import { Dependencies } from "..";

export const build = ({ http, abortController }: Dependencies) => {
  const REGISTER_PATH = "/register";
  const execute = (data: Users) => {
    const controller = abortController();
    return {
      controller,
      response: http.post<Users>(`${REGISTER_PATH}`, data, {
        signal: controller.signal,
      }),
    };
  };
  return execute;
};
