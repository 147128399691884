"use client";

import { useState } from "react";
import { User, Camera, Globe } from "lucide-react";
import { useTheme } from "next-themes";
import { Avatar, AvatarFallback, AvatarImage } from "@components/ui/avatar";
import { Button } from "@components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@components/ui/card";
import { Input } from "@components/ui/input";
import { Label } from "@components/ui/label";
import { Switch } from "@components/ui/switch";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@components/ui/tabs";
import { Textarea } from "@components/ui/textarea";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@components/ui/select";
import { Badge } from "@components/ui/badge";
import Narbar from "@components/narbar/narbar";

export default function UserProfilePage() {
  const Picture =
    "https://avatars.githubusercontent.com/u/141464312?s=400&u=3f2d88d3b5f3f5750243e0eb361ce472774485fb&v=4";

  const [user, setUser] = useState({
    name: "Cesar Andres Pereira",
    email: "cesarandresdev@gmail.com",
    avatar: Picture,
    bio: "Desarrollador de software apasionado por la tecnología y la innovación.",
    location: "Bogota, Colombia",
    website: "https://cesardev.dev",
    joinDate: "Enero 2020",
    role: "Desarrollador Senior",
    department: "Ingeniería de Software",
  });
  const { theme, setTheme } = useTheme();

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  return (
    <div>
      <Narbar />
      <div className="container mx-auto py-10">
        <Card className="max-w-4xl mx-auto">
          <CardHeader className="space-y-1">
            <div className="flex items-center gap-4">
              <div className="relative">
                <Avatar className="h-24 w-24">
                  <AvatarImage src={user.avatar} alt={user.name} />
                  <AvatarFallback>
                    <User className="h-12 w-12" />
                  </AvatarFallback>
                </Avatar>
                <Button
                  size="icon"
                  className="absolute bottom-0 right-0 rounded-full"
                >
                  <Camera className="h-4 w-4" />
                </Button>
              </div>
              <div>
                <CardTitle className="text-2xl">{user.name}</CardTitle>
                <CardDescription>{user.email}</CardDescription>
                <div className="flex items-center mt-2 text-sm text-muted-foreground">
                  <Globe className="h-4 w-4 mr-1" />
                  {user.location}
                </div>
                <Badge variant="secondary" className="mt-2">
                  {user.role}
                </Badge>
              </div>
            </div>
          </CardHeader>
          <CardContent>
            <Tabs defaultValue="profile" className="space-y-4">
              <TabsList>
                <TabsTrigger value="profile">Perfil</TabsTrigger>
                <TabsTrigger value="account">Cuenta</TabsTrigger>
                <TabsTrigger value="security">Seguridad</TabsTrigger>
                <TabsTrigger value="notifications">Notificaciones</TabsTrigger>
              </TabsList>
              <TabsContent value="profile" className="space-y-4">
                <div className="grid gap-4 md:grid-cols-2">
                  <div className="space-y-2">
                    <Label htmlFor="name">Nombre completo</Label>
                    <Input
                      id="name"
                      name="name"
                      value={user.name}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="space-y-2">
                    <Label htmlFor="email">Correo electrónico</Label>
                    <Input
                      id="email"
                      name="email"
                      type="email"
                      value={user.email}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="space-y-2">
                    <Label htmlFor="role">Rol</Label>
                    <Input
                      id="role"
                      name="role"
                      value={user.role}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="space-y-2">
                    <Label htmlFor="department">Departamento</Label>
                    <Input
                      id="department"
                      name="department"
                      value={user.department}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="space-y-2 md:col-span-2">
                    <Label htmlFor="bio">Biografía</Label>
                    <Textarea
                      id="bio"
                      name="bio"
                      value={user.bio}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="space-y-2">
                    <Label htmlFor="website">Sitio web</Label>
                    <Input
                      id="website"
                      name="website"
                      value={user.website}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="space-y-2">
                    <Label htmlFor="location">Ubicación</Label>
                    <Input
                      id="location"
                      name="location"
                      value={user.location}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
                <Button>Guardar cambios</Button>
              </TabsContent>
              <TabsContent value="account" className="space-y-4">
                <div className="space-y-2">
                  <Label htmlFor="language">Idioma</Label>
                  <Select defaultValue="es">
                    <SelectTrigger id="language">
                      <SelectValue placeholder="Selecciona un idioma" />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectItem value="es">Español</SelectItem>
                      <SelectItem value="en">English</SelectItem>
                      <SelectItem value="fr">Français</SelectItem>
                    </SelectContent>
                  </Select>
                </div>
                <div className="flex items-center justify-between">
                  <Label htmlFor="dark-mode">Modo oscuro</Label>
                  <Switch
                    id="dark-mode"
                    checked={theme === "dark"}
                    onCheckedChange={() =>
                      setTheme(theme === "dark" ? "light" : "dark")
                    }
                  />
                </div>
                <div className="space-y-2">
                  <Label>Fecha de registro</Label>
                  <p className="text-sm text-muted-foreground">
                    {user.joinDate}
                  </p>
                </div>
              </TabsContent>
              <TabsContent value="security" className="space-y-4">
                <div className="space-y-2">
                  <h3 className="text-lg font-medium">Cambiar contraseña</h3>
                  <div className="grid gap-2">
                    <Label htmlFor="current-password">Contraseña actual</Label>
                    <Input id="current-password" type="password" />
                  </div>
                  <div className="grid gap-2">
                    <Label htmlFor="new-password">Nueva contraseña</Label>
                    <Input id="new-password" type="password" />
                  </div>
                  <div className="grid gap-2">
                    <Label htmlFor="confirm-password">
                      Confirmar nueva contraseña
                    </Label>
                    <Input id="confirm-password" type="password" />
                  </div>
                  <Button>Actualizar contraseña</Button>
                </div>
                <div className="space-y-2">
                  <h3 className="text-lg font-medium">
                    Autenticación de dos factores
                  </h3>
                  <p className="text-sm text-muted-foreground">
                    Añade una capa adicional de seguridad a tu cuenta.
                  </p>
                  <Button variant="outline">Configurar 2FA</Button>
                </div>
              </TabsContent>
              <TabsContent value="notifications" className="space-y-4">
                <div className="space-y-2">
                  <h3 className="text-lg font-medium">
                    Preferencias de notificación
                  </h3>
                  <div className="flex items-center justify-between">
                    <Label htmlFor="email-notifications">
                      Notificaciones por correo
                    </Label>
                    <Switch id="email-notifications" />
                  </div>
                  <div className="flex items-center justify-between">
                    <Label htmlFor="push-notifications">
                      Notificaciones push
                    </Label>
                    <Switch id="push-notifications" />
                  </div>
                  <div className="flex items-center justify-between">
                    <Label htmlFor="sms-notifications">
                      Notificaciones SMS
                    </Label>
                    <Switch id="sms-notifications" />
                  </div>
                </div>
              </TabsContent>
            </Tabs>
          </CardContent>
        </Card>
      </div>
    </div>
  );
}
